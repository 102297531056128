/* eslint-disable */
import * as React from 'react';
import {
  Typography, Box, Grid, InputAdornment, Checkbox, ToggleButton, ToggleButtonGroup, Stack, TextField, useMediaQuery, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import { MutableRefObject, useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import IconButton from '@mui/material/IconButton';

import IOSSwitch from './ItemBasicInformation.style';
import { getKeyValue } from '../../../../../../../services/common';
import addEditItemContext from '../../../../../../../context/addEditItem/addEditItemContext';
import { DialogCommon, LmiTheme } from '../../../../../../../components/Common';
import SuggestedMap from '../../../../../../../components/Common/Map/MapPlaces';
import { MapData } from '../../../../../../../components/Common/Map/Map.models';
import InputTextField from '../../../../../AddItem/Components/InputTextField/InputTextField';
import { EditRounded, LocationOnRounded } from '@mui/icons-material';
import StaticMap from '../../../../../../../components/Common/StaticMap/StaticMap';
import InputNumerictextField from '../../../../../AddItem/Components/InputNumericTextField/InputNumericTextField';
import { PRECING_DAILY, PRECING_HOURLY, PRECING_MONTHLY, PRECING_WEEKLY } from '../../../../../../../types/index';
import { useJsApiLoader } from '@react-google-maps/api';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

function ItemBasicInformation(props: any) {
  const addEditContextLog = useContext(addEditItemContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [openChangeAddress, setOpenChangeAddress] = React.useState(false);
  const [countryCode, setCountryCode] = React.useState<any>('');
  const [changeAddress, setChangeAddress] = React.useState<any>({
    countryName: '',
    cityName: '',
    state: '',
    street: '',
    zipCode: '',
  });


  const userCurrency = props.userCurrency;

  const inputTitleReference = useRef() as MutableRefObject<HTMLDivElement>;
  const inputDescriptionReference = useRef() as MutableRefObject<HTMLDivElement>;
  const inputLocationReference = useRef() as MutableRefObject<HTMLDivElement>;

  const {
    itemInfo,
    updateItemInfo,
    updateDraftItemInfo,
    checkValidate,
    updateValidationStatus,
    itemImages,
    setActivesRentalPrice,
    checkRoadieFields,
    resetItemInfo,
    updateAccessoriesList,
    updateRequirementsList,
  }: any = addEditContextLog;

  const { t } = useTranslation('global');

  const initialStartTime =itemInfo?.availabilityFromTime != null ? itemInfo?.availabilityFromTime : '2022-02-02T09:00';
  const initialEndTime = itemInfo?.availabilityToTime != null ? itemInfo?.availabilityToTime : '2022-02-02T17:00';

  const [toTime, setToTime] = React.useState(initialEndTime);
  const [fromTime, setFromTime] = React.useState(initialStartTime);

  // Validations
  const [isNameValid, setIsNameValid] = React.useState(true);
  const [isDescriptionValid, setIsDescriptionValid] = React.useState(true);
  const [isRCValid, setIsRCValid] = React.useState(true);
  const [isDailyValid, setIsDailyValid] = React.useState(true);
  const [isLocationValid, setIsLocationValid] = React.useState(true);
  const [cancelLat, setCancelLat] = React.useState('');
  const [cancelLng, setCancelLng] = React.useState('');
  const [cancelAddress, setCancelAddress] = React.useState('');
  const [preventFirstValidation, setPreventFirstValidation] = React.useState(true);

  // Placeholders
  const [titleHolder, setTitleHolder] = React.useState('item');

  // hourly checkboxes
  const [isHourlyActive, setIsHourlyActive] = React.useState(true);
  const [isDailyActive, setIsDailyActive] = React.useState(true);
  const [isWeeklyActive, setIsWeeklyActive] = React.useState(true);
  const [isMonthlyActive, setIsMonthlyActive] = React.useState(true);
  const [usrLat, setUsrLat] = React.useState(0);
  const [usrLng, setUsrLng] = React.useState(0);
  const [usrAddress, setUsrAddress] = useState('')

  const [addressHasChanged, setAddressHasChanged] = useState(false)

  const history = useHistory();
  const state = history.location.state as {
    kItemIsNew: boolean,
  };

  const [replacementCostValue, setReplacementCostValue] = React.useState(itemInfo?.replacementCost != undefined && itemInfo?.replacementCost > 0 ? itemInfo.replacementCost : null);
  const [salePriceValue, setSalePriceValue] = React.useState(itemInfo?.salePrice != undefined && itemInfo?.salePrice > 0 ? itemInfo.salePrice : `${t('add_item_page.enter_value')}`);

  const [libraries]: any = useState(['places']);
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
  });

  const updateItemForDraft = (obj: {}) => {
    if (itemInfo.idCatLndItem == 0) {
      updateDraftItemInfo({
        ...obj
      });
    }
  }

  const removeLocalStorage = () => {
    localStorage.removeItem('draftItemIdImage');
    localStorage.removeItem('draftItemIdVideo');
    localStorage.removeItem('draftItemInfo');
    localStorage.removeItem('draftAccessoriesInfo');
    localStorage.removeItem('draftRequirementList');
    localStorage.removeItem('draftBlockedIndDates');
  };

  useEffect(() => {
    if (props?.isReset) {
      removeLocalStorage();
      updateAccessoriesList([]);
      updateRequirementsList([]);
      updateItemInfo({
        ...itemInfo,
        hourlyRentalPrice: 0,
        dailyRentalPrice: 0,
        weeklyRentalPrice: 0,
        monthlyRentalPrice: 0,
        replacementCost: 0,
        salePrice: 0,
        name: '',
        dscription: '',
        address: '',
        street: '',
      });
      setReplacementCostValue(0);
      setSalePriceValue(0);
      resetItemInfo();
    }
  }, [props.isReset]);

  useEffect(() => {
    if (itemInfo.title != "" && itemInfo.idCatLndItem != 0) {
      if (itemInfo.dailyRentalPrice <= 0) {
        setIsDailyActive(false);
        setActivesRentalPrice(false, PRECING_DAILY);
      }

      if (itemInfo.hourlyRentalPrice <= 0) {
        setIsHourlyActive(false);
        setActivesRentalPrice(false, PRECING_HOURLY);
      }

      if (itemInfo.weeklyRentalPrice <= 0) {
        setIsWeeklyActive(false);
        setActivesRentalPrice(false, PRECING_WEEKLY);
      }

      if (itemInfo.monthlyRentalPrice <= 0) {
        setIsMonthlyActive(false);
        setActivesRentalPrice(false, PRECING_MONTHLY);
      }

      if (itemInfo?.availabilityToTime != '' && itemInfo?.availabilityToTime != undefined) {
        const toTimeArray = itemInfo?.availabilityToTime.split(' ');
        console.log("timeArray");
        console.log(toTimeArray);
        const dateArray = moment(toTimeArray[0], ["MM/DD/YYYY", "DD/MM/YYYY", "YYYY-MM-DD"]).format('DD/MM/YYYY').split('/');
        const timeArray = toTimeArray[1].split(':');
        if (timeArray[0]?.length === 1) {
          timeArray[0] = `0${timeArray[0]}`;
        }
        if (timeArray[1]?.length === 1) {
          timeArray[1] = `0${timeArray[1]}`;
        }
        let time = `${timeArray[0]}:${timeArray[1]}`;
        if (dateArray[0]?.length === 1) {
          dateArray[0] = `0${dateArray[0]}`;
        }
        if (dateArray[1]?.length === 1) {
          dateArray[1] = `0${dateArray[1]}`;
        }
        let date = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}T${time}`;
        if (toTimeArray.length > 2) {
          date = `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}T${time}`;
          if (toTimeArray[2] === 'PM' && moment(`${time}`, ['HH']).format('HH') != '12') {
            date = moment(`${date}`).add(12, 'hours').format('YYYY-MM-DDTHH:mm');
          }
        }
        setToTime(date);
      }

      if (itemInfo?.availabilityFromTime != '' && itemInfo?.availabilityFromTime != undefined) {
        const fromTimeArray = itemInfo?.availabilityFromTime.split(' ');
        const dateArray = moment(fromTimeArray[0], ["MM/DD/YYYY", "DD/MM/YYYY", "YYYY-MM-DD"]).format('DD/MM/YYYY').split('/');
        const timeArray = fromTimeArray[1].split(':');
        if (timeArray[0].length === 1) {
          timeArray[0] = `0${timeArray[0]}`;
        }
        if (timeArray[1].length === 1) {
          timeArray[1] = `0${timeArray[1]}`;
        }
        let time = `${timeArray[0]}:${timeArray[1]}`;
        if (dateArray[0].length === 1) {
          dateArray[0] = `0${dateArray[0]}`;
        }
        if (dateArray[1].length === 1) {
          dateArray[1] = `0${dateArray[1]}`;
        }
        let date = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}T${time}`;
        if (fromTimeArray.length > 2) {
          date = `${dateArray[2]}-${dateArray[0]}-${dateArray[1]}T${time}`;
          if (fromTimeArray[2] === 'PM' && moment(`${time}`, ['HH']).format('HH') != '12') {
            date = moment(`${date}`).add(12, 'hours').format('YYYY-MM-DDTHH:mm');
          }
        }
        setFromTime(date);
      }
    }
  }, [])

  const scrollToErrorField = (fieldRef: any) => {
    if (fieldRef?.current) {
      fieldRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  };

  useEffect(() => {
    let isEverythingOk = true;

    const validationCheck = (condition: any, errorFunction: any, setValidityFunction: any) => {
      if (condition) {
        if (isEverythingOk) {
          errorFunction();
        }
        isEverythingOk = false;
        setValidityFunction(false);
      }
    };

    if (checkValidate) {
      validationCheck(
        !itemInfo.name || itemInfo.name.length == 0,
        () => scrollToErrorField(inputTitleReference),
        setIsNameValid
      );

      validationCheck(
        !itemInfo.dscription || itemInfo.dscription.length == 0,
        () => scrollToErrorField(inputDescriptionReference),
        setIsDescriptionValid
      );

      if (itemInfo.idCatTypeItem != 2 && itemInfo.idCatTypeItem != 3) {
        validationCheck(
          itemInfo.replacementCost <= 0,
          () => scrollToErrorField(inputTitleReference),
          setIsRCValid
        );
      }

      const invalidLocation = itemInfo.lat == '' || itemInfo.lon == '';

      validationCheck(
        invalidLocation,
        () => scrollToErrorField(inputLocationReference),
        setIsLocationValid
      );

      if (!invalidLocation) {
        if (itemInfo.roadie && countryCode != "US") {
          // toast.error("Roadie is only available in the US");
          // validationCheck(
          //  true,
          //  () => scrollToErrorField(inputLocationReference),
          //  setIsLocationValid
          // );
        } else {
          setIsLocationValid(true);
        }
      }

      validationCheck(
        itemImages && itemImages.length == 0,
        () => { },
        () => { }
      );

      if (!isEverythingOk) {
        updateValidationStatus(isEverythingOk);
      } else {
        itemInfo?.idCatTypeItem === 1 || itemInfo?.idCatTypeItem === '1' 
        ? checkRoadieFields(true) : updateValidationStatus(true);
      }
    }

    setPreventFirstValidation(false);
  }, [checkValidate]);


  useEffect(() => {
    if (itemInfo.idCatTypeItem == 1) {
      setTitleHolder('item');
    } else if (itemInfo.idCatTypeItem == 2) {
      setTitleHolder('service');
    } else {
      setTitleHolder('place');
    }
  }, [itemInfo.idCatTypeItem]);

  useEffect(() => {
    const rpCost = itemInfo.replacementCost;
    let dailyPrice = itemInfo.dailyRentalPrice;
    let hourlyPrice = itemInfo.hourlyRentalPrice;
    let weeklyPrice = itemInfo.weeklyRentalPrice;
    let monthlyPrice = itemInfo.monthlyRentalPrice;
    // New daily price that is not displayed because daily is not active
    let ghostDailyPrice = dailyPrice;
    let ghostWeeklyPrice = weeklyPrice;

    const minValue = userCurrency == 'MXN' ? 50 : 5;

    if (!preventFirstValidation && !props.isReset) {

      if (isDailyActive) {
        dailyPrice = Math.round(rpCost * 0.01) > minValue
          ? Math.round(rpCost * 0.01)
          : minValue;
        ghostDailyPrice = dailyPrice;
      } else {
        ghostDailyPrice = Math.round(rpCost * 0.01) > minValue
          ? Math.round(rpCost * 0.01)
          : minValue;
      }
      // hourlyPrice
      if (isHourlyActive) {
        hourlyPrice = Math.round(ghostDailyPrice / 2) > minValue
          ? Math.round(ghostDailyPrice / 2)
          : minValue;
      }
      // weeklyPrice
      if (isWeeklyActive) {
        weeklyPrice = Math.round(ghostDailyPrice * 4) > minValue
          ? Math.round(ghostDailyPrice * 4)
          : minValue;
        ghostWeeklyPrice = weeklyPrice;
      } else {
        // weeklyPrice == 0;
        ghostWeeklyPrice = Math.round(ghostDailyPrice * 4) > minValue
          ? Math.round(ghostDailyPrice * 4)
          : minValue;
      }

      // monthlyPrice
      if (isMonthlyActive) {
        monthlyPrice = Math.round(ghostWeeklyPrice * 3) > minValue
          ? Math.round(ghostWeeklyPrice * 3)
          : minValue;
      }

      updateItemInfo({
        ...itemInfo,
        hourlyRentalPrice: hourlyPrice,
        dailyRentalPrice: dailyPrice,
        weeklyRentalPrice: weeklyPrice,
        monthlyRentalPrice: monthlyPrice,
      });
      updateItemForDraft({
        ...itemInfo,
        hourlyRentalPrice: hourlyPrice,
        dailyRentalPrice: dailyPrice,
        weeklyRentalPrice: weeklyPrice,
        monthlyRentalPrice: monthlyPrice,
      })
    }
  }, [itemInfo.replacementCost]);


  useEffect(() => {
    const localLat = localStorage.getItem('lat');
    const localLng = localStorage.getItem('lng');
    const localAddress = localStorage.getItem('longAddress');
    switch (state.kItemIsNew) {
      case true:
        
        if (localLat === null && localLng === null) {
          if (itemInfo?.lat === "" && itemInfo?.lon === "") {
            navigator.geolocation.getCurrentPosition((position) => {
              setUsrLat(position.coords.latitude);
              setUsrLng(position.coords.longitude);
              getCountryCode(position.coords.latitude, position.coords.longitude);
            }, () => {
              setUsrLat(34.0536909);
              setUsrLng(-118.242766);
              getCountryCode(34.0536909, -118.242766);
            });
          } else {
            setUsrLat(parseFloat(itemInfo?.lat));
            setUsrLng(parseFloat(itemInfo?.lon));
            setUsrAddress(itemInfo?.address);
            getCountryCode(parseFloat(itemInfo?.lat), parseFloat(itemInfo?.lon));
          }
        } else {
          setUsrLat(localLat ? parseFloat(localLat) : 34.0536909);
          setUsrLng(localLng ? parseFloat(localLng) : -118.242766);
          setUsrAddress(localAddress ? localAddress : '');
          getCountryCode(localLat ? parseFloat(localLat) : 34.0536909, localLng ? parseFloat(localLng) : -118.242766);
        }
        break;
      case false:
        setUsrLat(parseFloat(itemInfo?.lat));
        setUsrLng(parseFloat(itemInfo?.lon));
        setUsrAddress(itemInfo?.address);
        getCountryCode(parseFloat(itemInfo?.lat), parseFloat(itemInfo?.lon));
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (usrAddress && usrLat && usrLng && changeAddress.countryName) {
      updateItemInfo({
        ...itemInfo,
        address: usrAddress,
        lat: `${usrLat}`,
        lon: `${usrLng}`,
        city: changeAddress.cityName,
        country: changeAddress.countryName,
        street: changeAddress.street,
        state: changeAddress.state,
        zipCode: changeAddress.zipCode,
      });
      updateDraftItemInfo({
        ...itemInfo,
        address: usrAddress,
        lat: `${usrLat}`,
        lon: `${usrLng}`,
        city: changeAddress.cityName,
        country: changeAddress.countryName,
        street: changeAddress.streetAddress,
        state: changeAddress.state,
        zipCode: changeAddress.zipCode,
      });
    }
  }, [usrLat, usrLng, usrAddress, changeAddress]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log(data);
  };

  const openMap = () => {
    setOpenChangeAddress(true);
    setCancelLat(itemInfo.lat);
    setCancelLng(itemInfo.lon);
    setCancelAddress(itemInfo.address);
  };

  const cancelCloseMap = () => {
    setOpenChangeAddress(!openChangeAddress);

    updateItemInfo({
      ...itemInfo,
      lat: cancelLat,
      lon: cancelLng,
      address: cancelAddress,
    });
    updateItemForDraft({
      ...itemInfo,
      lat: cancelLat,
      lon: cancelLng,
      address: cancelAddress,
    });
    getCountryCode(parseFloat(cancelLat), parseFloat(cancelLng));
  };

  const changeListingType = (e: any) => {
    updateItemInfo({
      ...itemInfo,
      idCatTypeItem: Number(e.target.value),
    });
    updateItemForDraft({
      ...itemInfo,
      idCatTypeItem: Number(e.target.value),
    });
  };


  const changeHourlyCheckbox = () => {
    let price = 0;
    if (!props.isReset) {
      const minValue = userCurrency == 'MXN' ? 50 : 5;
      price =
        !isHourlyActive && itemInfo.hourlyRentalPrice < minValue
          || isNaN(parseFloat(`${itemInfo.hourlyRentalPrice}`))
          ? minValue : 0;
    }
    updateItemInfo({
      ...itemInfo,
      hourlyRentalPrice: price,
    });
    updateItemForDraft({
      ...itemInfo,
      hourlyRentalPrice: price,
    });

    setIsHourlyActive(!isHourlyActive);
    setActivesRentalPrice(!isHourlyActive, PRECING_HOURLY);
  };

  const changeDailyCheckbox = () => {
    let price = 0;
    if (!props.isReset) {
      const minValue = userCurrency == 'MXN' ? 50 : 5;
      price =
        !isDailyActive && itemInfo.dailyRentalPrice < minValue
          || isNaN(parseFloat(`${itemInfo.dailyRentalPrice}`)) ? minValue : 0;
    }

    updateItemInfo({
      ...itemInfo,
      dailyRentalPrice: price,
    });

    updateItemForDraft({
      ...itemInfo,
      dailyRentalPrice: price,
    });

    setIsDailyActive(!isDailyActive);
    setActivesRentalPrice(!isDailyActive, PRECING_DAILY);
  };

  const changeWeeklyCheckbox = () => {
    let price = 0;
    if (!props.isReset) {
      const minValue = userCurrency == 'MXN' ? 50 : 5;
      price =
        !isWeeklyActive && itemInfo.weeklyRentalPrice < minValue
          || isNaN(parseFloat(`${itemInfo.weeklyRentalPrice}`)) ? minValue : 0;
    }

    updateItemInfo({
      ...itemInfo,
      weeklyRentalPrice: price,
    });

    updateItemForDraft({
      ...itemInfo,
      weeklyRentalPrice: price,
    });

    setIsWeeklyActive(!isWeeklyActive);
    setActivesRentalPrice(!isWeeklyActive, PRECING_WEEKLY);
  };

  const changeMonthlyCheckbox = () => {

    let price = 0;
    if (!props.isReset) {
      const minValue = userCurrency == 'MXN' ? 50 : 5;
      price =
        !isMonthlyActive && itemInfo.monthlyRentalPrice < minValue
          || isNaN(parseFloat(`${itemInfo.monthlyRentalPrice}`)) ? minValue : 0;
    }

    updateItemInfo({
      ...itemInfo,
      monthlyRentalPrice: price,
    });

    updateItemForDraft({
      ...itemInfo,
      monthlyRentalPrice: price,
    });

    setIsMonthlyActive(!isMonthlyActive);
    setActivesRentalPrice(!isMonthlyActive, PRECING_MONTHLY);
  }

  const changeIsForSale = () => {
    updateItemInfo({
      ...itemInfo,
      accept_offers: !itemInfo.accept_offers,
      acceptOffers: !itemInfo.accept_offers,
    });
    updateItemForDraft({
      ...itemInfo,
      accept_offers: !itemInfo.accept_offers,
      acceptOffers: !itemInfo.accept_offers,
    });
  };

 

  const [acceptOffers, setAcceptOffers] = useState(false);

  useEffect(() => {
    setAcceptOffers(itemInfo.accept_offers)
  }, [itemInfo.accept_offers])


  const onChange = (e: any) => {
    const dataType = getKeyValue(itemInfo, e.target.name);

    let valueBiggerThanMinimum;
    if (userCurrency == 'USD') {
      valueBiggerThanMinimum = parseFloat(e.target.value) < 5;
    }
    if (userCurrency == 'MXN') {
      valueBiggerThanMinimum = parseFloat(e.target.value) < 50
    }


    let value;
    if (e.target.name === 'name') {
      if (e.target.value.length == 0) {
        setIsNameValid(false);
      } else {
        setIsNameValid(true);
      }
      e.target.value = e.target.value.substring(0, 45);
    }
    if (e.target.name === 'dscription') {
      if (e.target.value.length == 0) {
        setIsDescriptionValid(false);
      } else {
        setIsDescriptionValid(true);
      }
      e.target.value = e.target.value.substring(0, 420);
    }
    if (e.target.name === 'replacementCost') {
      props.setIsReset(false);

      if (e.target.value === "") {
        setIsRCValid(false);
        setReplacementCostValue(0);
        return;
      }

      let inputValue = parseFloat(e.target.value);

      if (isNaN(inputValue) || inputValue <= 0) {
        setIsRCValid(false);
      } else if (inputValue > 999999) {
        alert(t('add_item_page.item_price_alert'));
        inputValue = 999999;
        setIsRCValid(true);
      } else {
        setIsRCValid(true);
      }

      setReplacementCostValue(inputValue);
    }

    if (e.target.name === 'salePrice') {
      setSalePriceValue(value);
    }
    if (e.target.name === 'dailyRentalPrice' && props.isReset === false) {
      if (!e.target.value || e.target.value == '' || valueBiggerThanMinimum) {
        setIsDailyValid(false);
        setIsDailyActive(false);
        setActivesRentalPrice(false, PRECING_DAILY);
        if (e.target.value == '') { e.target.value == '0' }
      } else {
        setIsDailyValid(true);
        setIsDailyActive(true);
        setActivesRentalPrice(true, PRECING_DAILY);
      }
    }
    if (e.target.name === 'hourlyRentalPrice' && props.isReset === false) {
      if (!e.target.value || e.target.value == '' || valueBiggerThanMinimum) {
        setIsHourlyActive(false);
        setActivesRentalPrice(false, PRECING_HOURLY);
        if (e.target.value == '') { e.target.value == '0' }
      } else {
        setIsHourlyActive(true);
        setActivesRentalPrice(true, PRECING_HOURLY);
      }
    }
    if (e.target.name === 'weeklyRentalPrice' && props.isReset === false) {
      if (!e.target.value || e.target.value == '' || valueBiggerThanMinimum) {
        setIsWeeklyActive(false);
        setActivesRentalPrice(false, PRECING_WEEKLY);
        if (e.target.value == '') { e.target.value == '0' }
      } else {
        setIsWeeklyActive(true);
        setActivesRentalPrice(true, PRECING_WEEKLY);
      }
    }
    if (e.target.name === 'monthlyRentalPrice' && props.isReset === false) {
      if (!e.target.value || e.target.value == '' || valueBiggerThanMinimum) {
        setIsMonthlyActive(false);
        setActivesRentalPrice(false, PRECING_MONTHLY);
        if (e.target.value == '') { e.target.value == '0' }
      } else {
        setIsMonthlyActive(true);
        setActivesRentalPrice(true, PRECING_MONTHLY);
      }
    }
    if (e.target.name === 'lastName') {
      e.target.value = e.target.value.substring(0, 200);
    }
    if (e.target.name === 'email') {
      e.target.value = e.target.value.substring(0, 200);
    }
    switch (typeof (dataType)) {
      case 'number':
        value = parseFloat(e.target.value != '' ? e.target.value : 5);
        break;
      case 'boolean':
        value = e.target.checked;
        break;
      default:
        value = e.target.value;
        break;
    }

    updateItemInfo({
      ...itemInfo,
      [e.target.name]: value,
    });
    updateItemForDraft({
      ...itemInfo,
      [e.target.name]: value,
    });
  };


  const updateMapInfo = React.useCallback((obj: {}) => {
    updateItemForDraft(obj);
  }, [itemInfo])

  const updateMapa = (mapaData: MapData) => {
    const item_info = localStorage.getItem('draftItemInfo');
    const item_info_json = !item_info ? itemInfo : JSON.parse(item_info);
    // const item_info_json = itemInfo.idCatLndItem == 0 ? item_info !== null && JSON.parse(item_info) : itemInfo;

    updateItemInfo(
      {
        ...item_info_json,
        address: mapaData.address,
        lat: mapaData.lat,
        lon: mapaData.long,
        street: mapaData.streetAddress,
        extendedAddress: mapaData.extendedAddress,
        city: mapaData.locality,
        state: mapaData.region,
        zipCode: mapaData.postalCode,
        country: mapaData.countryName,
      },
    );

    updateMapInfo({
      ...itemInfo,
      address: mapaData.address,
      lat: mapaData.lat,
      lon: mapaData.long,
      street: mapaData.streetAddress,
      extendedAddress: mapaData.extendedAddress,
      city: mapaData.locality,
      state: mapaData.region,
      zipCode: mapaData.postalCode,
      country: mapaData.countryName,
    });

    setCountryCode(mapaData.countryCode);
  };

  const changeStartTime = (e: any) => {
    if (itemInfo.availabilityToTime != '') {
      let starTime = new Date(moment(e).format('YYYY-MM-DDTHH:mm'));
      let endTime = new Date(itemInfo.availabilityToTime);
      if (typeof starTime != 'object') {
        starTime = new Date(starTime);
      }
      const diff = (endTime.getTime() - starTime.getTime()) / 3600000;
      if (diff < 1) {
        toast.error(t('add_item_page.time_period_error'));
      } else {
        const dateString = moment(e).format('YYYY-MM-DDTHH:mm');
        setFromTime(dateString);
        updateItemInfo({
          ...itemInfo,
          availabilityFromTime: dateString,
        });
        updateItemForDraft({
          ...itemInfo,
          availabilityFromTime: dateString,
        });
      }
    }
  };

  const changeEndTime = (e: any) => {
    if (itemInfo.availabilityFromTime != '') {
      let endTime = new Date(moment(e).format('YYYY-MM-DDTHH:mm'));
      const startTime = new Date(itemInfo.availabilityFromTime);
      if (typeof endTime != 'object') {
        endTime = new Date(endTime);
      }
      const diff = (endTime.getTime() - startTime.getTime()) / 3600000;
      if (diff < 1) {
        toast.error(t('add_item_page.time_period_error'));
      } else {
        const dateString = moment(e).format('YYYY-MM-DDTHH:mm');
        setToTime(dateString);
        
        updateItemInfo({
          ...itemInfo,
          availabilityToTime: dateString,
        });
        updateItemForDraft({
          ...itemInfo,
          availabilityToTime: dateString,
        });
        
      }
    }
  };

  const changeNameLocation = React.useMemo(() => {
    const parseStatus = parseInt(itemInfo?.idCatTypeItem)
    const itemLocationName = t('item_detail.item_location')
    const serviceLocationName = t('item_detail.service_location');
    const placeLocationName = t('item_detail.place_location')

    if (parseStatus === 1) return itemLocationName

    if (parseStatus === 2) return serviceLocationName

    if (parseStatus === 3) return placeLocationName

    return ''
  }, [itemInfo?.idCatTypeItem]);

  const changeExactNameLocation = React.useMemo(() => {
    const parseStatus = parseInt(itemInfo?.idCatTypeItem)
    const itemLocationName = t('add_item_page.exact_location');
    const serviceLocationName = t('add_item_page.exact_service_location');
    const placeLocationName = t('add_item_page.exact_place_location');

    if (parseStatus === 1) return itemLocationName

    if (parseStatus === 2) return serviceLocationName

    if (parseStatus === 3) return placeLocationName

    return ''
  }, [itemInfo?.idCatTypeItem])

  useEffect(() => {
    if (itemInfo?.idCatTypeItem) {
      changeNameLocation
      changeExactNameLocation
    }
  }, [])

  async function getCountryCode(lat: any, lng: any,) {

    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY!}`);
      const data = await response.json();

      const addressComponents = data.results[0]?.address_components.reduce((acc: any, curr: any) => {
        const { short_name, long_name, types } = curr;
        if (types.includes('route')) {
          acc.streetAddress = long_name;
        } else if (types.includes('country')) {
          acc.countryName = long_name;
          acc.countryCode = short_name;
        } else if (types.includes('locality')) {
          acc.city = long_name;
        }  else if (types.includes('administrative_area_level_1')) {
          acc.state = short_name;
        } else if (types.includes('postal_code')) {
          acc.postalCode = long_name;
        } else if (types.includes('street_number')) {
          acc.streetNum = long_name;
        }
        return acc;
      });
      setCountryCode(addressComponents.countryCode);
      setChangeAddress({
        countryName: addressComponents.countryName,
        cityName: addressComponents.city,
        state: addressComponents.state,
        street: `${addressComponents.long_name} ${addressComponents.streetAddress}`.trim(),
        zipCode: addressComponents.postalCode,
      });
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <Box component="form" noValidate={false} onSubmit={handleSubmit}>
        <Grid container justifyContent="center">
          <ToggleButtonGroup
            color="primary"
            value={typeof (itemInfo?.idCatTypeItem) == 'string' ? parseInt(itemInfo?.idCatTypeItem) : itemInfo?.idCatTypeItem}
            exclusive
            onChange={changeListingType}
            fullWidth
            disabled={props.isBlocked}
          >
            <ToggleButton value={1}>{t('add_item_page.Item')}</ToggleButton>
            <ToggleButton value={2}>{t('add_item_page.Service')}</ToggleButton>
            <ToggleButton value={3}>{t('add_item_page.Place')}</ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Typography fontSize="body2.fontSize" pt={3} pb={1} color={LmiTheme.light.systemBackground.tertiary}>
          {t('add_item_page.fill_information_txt')}
        </Typography>
        <Grid
          container
          spacing={2}
          rowSpacing={0}
          component="form"
          noValidate
          autoComplete="off"
        >
          <Grid item xs={12} md={5}>
            <Stack direction="column">
              <InputTextField
                kLabel={t('add_item_page.title')}
                kInputMaxLength={45}
                kInputId={'title'}
                kInputType={'text'}
                kInputPlaceholder={`${t('add_item_page.enter_title')}${t(`add_item_page.${titleHolder}`).toLowerCase()}`}
                kInputValue={itemInfo?.name || ''}
                kDisabled={props.isBlocked}
                kInputMultiline={false}
                kRef={inputTitleReference}
                kInputRows={1}
                kInputMaxRows={1}
                kInputOnChange={onChange}
                kInputRequired
                kInputError={!isNameValid}
                kInputName="name"
                kInputCurrentLength={itemInfo?.name ? itemInfo?.name.length : 0}
                kInputHelperText={!isNameValid ? `${t('add_item_page.required_field')}` : ''}
              />
              <Box mt={2} />
              <InputTextField
                kLabel={t('item_detail.description')}
                kInputMaxLength={420}
                kInputId={'description'}
                kInputType={'text'}
                kInputPlaceholder={`${t('add_item_page.enter_description')}${t(`add_item_page.${titleHolder}`).toLowerCase()}`}
                kInputValue={itemInfo?.dscription != undefined ? itemInfo.dscription : ''}
                kDisabled={props.isBlocked}
                kInputMultiline
                kInputRows={5}
                kRef={inputDescriptionReference}
                kInputOnChange={onChange}
                kInputRequired
                kInputCurrentLength={itemInfo?.dscription ? itemInfo?.dscription.length : 0}
                kInputError={!isDescriptionValid}
                kInputName="dscription"
                kInputHelperText={!isDescriptionValid ? `${t('add_item_page.required_field')}` : ''}
              />
            </Stack>
          </Grid>
          <Grid item sm={12} md={7}>
            <Stack direction="column">
              <Grid container spacing={2} >
                <Grid item xs={5}>
                  <InputNumerictextField
                    kLabel={`${t('item_detail.item_value')}${t(`add_item_page.${titleHolder}`).toLowerCase()}`}
                    kInputMaxLength={7}
                    kInputId={'itemvalue'}
                    kInputType="text"
                    kInputPlaceholder={t('item_detail.enter_value')}
                    kInputValue={replacementCostValue}
                    kInputMultiline={false}
                    kInputMaxLengthHide
                    kPrecision={0}
                    kDisabled={props.isBlocked || itemInfo?.idCatTypeItem != 1}
                    kInputRows={1}
                    kInputMaxRows={1}
                    kInputOnChange={onChange}
                    kInputRequired
                    kInputError={(!isRCValid && itemInfo?.idCatTypeItem == 1)}
                    kInputName="replacementCost"
                    kInputHelperText={(!isRCValid && itemInfo?.idCatTypeItem == 1) ? `${t('add_item_page.required_field')}` : ''}
                    kStartAdornment={
                      itemInfo?.replacementCost > 0 ?
                        <InputAdornment position="start">
                          <Typography color={LmiTheme.light.systemBackground.tertiary} fontSize="body2.fontSize">$</Typography>
                        </InputAdornment>
                        : <></>
                    }
                    kEndAdornment={
                      <InputAdornment position="end">
                        <Typography color={LmiTheme.light.systemBackground.secondary} fontSize="body2.fontSize">{userCurrency}</Typography>
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={5}>
                  <InputNumerictextField
                    kLabel={t('item_detail.for_sale')}
                    kInputMaxLength={7}
                    kInputId={'sale'}
                    kInputType="text"
                    kInputPlaceholder={t('item_detail.enter_value')}
                    kInputMaxLengthHide
                    kInputValue={salePriceValue}
                    kInputMultiline={false}
                    kPrecision={0}
                    kDisabled={props.isBlocked || itemInfo?.idCatTypeItem != 1 || !acceptOffers}
                    kInputRows={1}
                    kInputMaxRows={1}
                    kInputOnChange={onChange}
                    kInputRequired={false}
                    kInputName="salePrice"
                    kLabelRequiredHide
                    kStartAdornment={
                      itemInfo?.salePrice > 0 ?
                        <InputAdornment position="start">
                          <Typography color={LmiTheme.light.systemBackground.tertiary} fontSize="body2.fontSize">$</Typography>
                        </InputAdornment>
                        : <></>
                    }
                    kEndAdornment={
                      <InputAdornment position="end">
                        <Typography color={LmiTheme.light.systemBackground.secondary} fontSize="body2.fontSize">{userCurrency}</Typography>
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <Box
                    sx={{ display: 'flex', justifyContent: 'center', pt: 6 }}
                  >
                    <IOSSwitch disabled={props.isBlocked || itemInfo?.idCatTypeItem != 1} checked={acceptOffers} onChange={changeIsForSale} sx={{ m: '0 auto' }} />
                  </Box>
                </Grid>
              </Grid>
              <Box mt={2} />
              <Grid container spacing={1} rowSpacing={0}>
                <Grid item xs={12}>
                  <Typography component="span" fontSize="caption.fontSize" fontWeight="bold" color={LmiTheme.light.accent}>* </Typography>
                  <Typography component="span" fontSize="caption.fontSize" fontWeight={500} color={LmiTheme.light.systemBackground.secondary}>
                    {t('item_detail.rental_price')}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <InputNumerictextField
                    kInputClassName='price-center'
                    kInputId={'hourly'}
                    kLabel={t('item_detail.hourly')}
                    kLabelCentered
                    kLabelColor={LmiTheme.light.systemBackground.tertiary}
                    kLabelRequiredHide
                    kInputMaxLength={7}
                    kInputMaxLengthHide
                    kPrecision={0}
                    kDisabled={props.isBlocked}
                    kInputType="text"
                    kInputPlaceholder="0"
                    kInputTextAlign="center"
                    kInputValue={itemInfo?.hourlyRentalPrice != undefined ? itemInfo.hourlyRentalPrice : '0'}
                    kInputMultiline={false}
                    kInputRows={1}
                    kInputMaxRows={1}
                    kInputOnChange={onChange}
                    kInputRequired
                    kInputName="hourlyRentalPrice"
                    kStartAdornment={
                      <InputAdornment position="start">
                        <Typography color={LmiTheme.light.systemBackground.tertiary} fontSize="body2.fontSize">$</Typography>
                      </InputAdornment>
                    }
                  />
                  <Stack direction="row" justifyContent="center">
                    <Typography color={LmiTheme.light.systemBackground.secondary} fontSize="body2.fontSize">{userCurrency}</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="center">
                    <Checkbox checked={isHourlyActive} onChange={changeHourlyCheckbox} />
                  </Stack>

                </Grid>
                <Grid item xs={3}>
                  <InputNumerictextField
                    kInputClassName='price-center'
                    kInputId={'daily'}
                    kLabel={t('item_detail.daily')}
                    kLabelCentered
                    kLabelColor={LmiTheme.light.systemBackground.tertiary}
                    kLabelRequiredHide
                    kInputMaxLength={7}
                    kInputMaxLengthHide
                    kPrecision={0}
                    kDisabled={props.isBlocked}
                    kInputType="text"
                    kInputPlaceholder="0"
                    kInputTextAlign="center"
                    kInputValue={itemInfo?.dailyRentalPrice != undefined ? itemInfo.dailyRentalPrice : '0'}
                    kInputMultiline={false}
                    kInputRows={1}
                    kInputMaxRows={1}
                    kInputOnChange={onChange}
                    kInputRequired={false}
                    kInputError={!isDailyValid}
                    kInputName="dailyRentalPrice"
                    kStartAdornment={
                      <InputAdornment position="start">
                        <Typography color={LmiTheme.light.systemBackground.tertiary} fontSize="body2.fontSize">$</Typography>
                      </InputAdornment>
                    }
                  />
                  <Stack direction="row" justifyContent="center">
                    <Typography color={LmiTheme.light.systemBackground.secondary} fontSize="body2.fontSize">{userCurrency}</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="center">
                    <Checkbox checked={isDailyActive} onChange={changeDailyCheckbox} />
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <InputNumerictextField
                    kInputClassName='price-center'
                    kInputId={'weekly'}
                    kLabel={t('item_detail.weekly')}
                    kLabelCentered
                    kLabelColor={LmiTheme.light.systemBackground.tertiary}
                    kLabelRequiredHide
                    kInputMaxLength={7}
                    kInputMaxLengthHide
                    kPrecision={0}
                    kDisabled={props.isBlocked}
                    kInputType="text"
                    kInputPlaceholder="0"
                    kInputTextAlign="center"
                    kInputValue={itemInfo?.weeklyRentalPrice != undefined ? itemInfo.weeklyRentalPrice : '0'}
                    kInputMultiline={false}
                    kInputRows={1}
                    kInputMaxRows={1}
                    kInputOnChange={onChange}
                    kInputRequired={false}
                    kInputName="weeklyRentalPrice"
                    kStartAdornment={
                      <InputAdornment position="start">
                        <Typography color={LmiTheme.light.systemBackground.tertiary} fontSize="body2.fontSize">$</Typography>
                      </InputAdornment>
                    }
                  />
                  <Stack direction="row" justifyContent="center">
                    <Typography color={LmiTheme.light.systemBackground.secondary} fontSize="body2.fontSize">{userCurrency}</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="center">
                    <Checkbox checked={isWeeklyActive} onChange={changeWeeklyCheckbox} />
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <InputNumerictextField
                    kInputClassName='price-center'
                    kInputId={'monthly'}
                    kLabel={t('item_detail.monthly')}
                    kLabelCentered
                    kLabelColor={LmiTheme.light.systemBackground.tertiary}
                    kLabelRequiredHide
                    kInputMaxLength={7}
                    kInputMaxLengthHide
                    kPrecision={0}
                    kDisabled={props.isBlocked}
                    kInputType="text"
                    kInputPlaceholder="0"
                    kInputTextAlign="center"
                    kInputValue={itemInfo?.monthlyRentalPrice != undefined ? itemInfo.monthlyRentalPrice : '0'}
                    kInputMultiline={false}
                    kInputRows={1}
                    kInputMaxRows={1}
                    kInputOnChange={onChange}
                    kInputRequired={false}
                    kInputName="monthlyRentalPrice"
                    kStartAdornment={
                      <InputAdornment position="start">
                        <Typography color={LmiTheme.light.systemBackground.tertiary} fontSize="body2.fontSize">$</Typography>
                      </InputAdornment>
                    }
                  />
                  <Stack direction="row" justifyContent="center">
                    <Typography color={LmiTheme.light.systemBackground.secondary} fontSize="body2.fontSize">{userCurrency}</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="center">
                    <Checkbox checked={isMonthlyActive} onChange={changeMonthlyCheckbox} />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Typography align="center" fontSize="subtitle2.fontSize" color={LmiTheme.light.systemBackground.tertiary}>
                    {t('add_item_page.deselect_options')}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </Grid>

          {isHourlyActive && <Grid item xs={12} >

            <Grid item xs={12}>
              <Typography py={1} fontSize="subtitle2.fontSize" color={LmiTheme.light.systemBackground.tertiary}>
                {t('add_item_page.rented_between')}
              </Typography>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDateFns} >
              <Grid
                container
                spacing={2}
                sx={{ mt: 0 }}
              >
                <Grid item xs={isMobile ? 6 : 5}>
                  <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                    <Typography fontSize="body2.fontSize" fontWeight={700} color={LmiTheme.light.systemBackground.secondary}>
                      {t('add_item_page.from')}
                    </Typography>
                    <TimePicker
                      cancelText={t('global.cancel')}
                      toolbarTitle={t('global.select_time')}
                      minTime={new Date(0, 0, 0, 9)}
                      maxTime={new Date(0, 0, 0, 19)}
                      views={['hours']}
                      onOpen={() => {
                        setTimeout(() => {
                          const el = document.activeElement;
                          if (el) {
                            (el as HTMLElement).blur();
                          }
                        });
                      }}
                      renderInput={({ inputProps, ...params }) => (
                        <TextField
                          variant="outlined"
                          sx={{
                            bgcolor: LmiTheme.light.systemBackground.base,
                            maxWidth: '170px',
                            textAlign: 'center',
                          }}
                          inputProps={{ ...inputProps, readOnly: true }}
                          {...params}
                        />
                      )}
                      value={fromTime}
                      disabled={props.kIsReadOnly}
                      onChange={(newValue: any) => {
                        changeStartTime(newValue);
                      }}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={isMobile ? 6 : 5}>
                  <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                    <Typography fontSize="body2.fontSize" fontWeight={700} color={LmiTheme.light.systemBackground.secondary}>
                      {t('add_item_page.to')}
                    </Typography>
                    <TimePicker
                      views={['hours']}
                      minTime={new Date(0, 0, 0, 12)}
                      maxTime={new Date(0, 0, 0, 23)}
                      onOpen={() => {
                        setTimeout(() => {
                          const el = document.activeElement;
                          if (el) {
                            (el as HTMLElement).blur();
                          }
                        });
                      }}
                      renderInput={({ inputProps, ...params }) => (
                        <TextField
                          variant="outlined"
                          sx={{
                            bgcolor: LmiTheme.light.systemBackground.base,
                            maxWidth: '170px',
                            textAlign: 'center',
                          }}
                          inputProps={{ ...inputProps, readOnly: true }}
                          {...params}
                        />
                      )}
                      value={toTime}
                      disabled={props.kIsReadOnly}
                      onChange={(newValue: any) => {
                        changeEndTime(newValue);
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </LocalizationProvider>
          </Grid>}

          <Grid item xs={12} sm={6}>
            <InputTextField
              kOnClick={openMap}
              kLabel={changeNameLocation}
              kInputMaxLengthHide
              kInputId={'location'}
              kInputType={'text'}
              kInputPlaceholder={t('item_detail.add_location')}
              kInputValue={
                !state.kItemIsNew
                  ? itemInfo?.address
                  : (!addressHasChanged ? usrAddress : itemInfo?.address)
              }
              kInputMultiline={false}
              kDisabled
              kInputRows={1}
              kInputMaxRows={1}
              kInputOnChange={onChange}
              kInputRequired
              kInputError={(!isLocationValid || (itemInfo?.lat == "" && itemInfo?.lon == ""))}
              kInputHelperText={(!isLocationValid && itemInfo?.lat == "" && itemInfo?.lon == "") ? `${t('item_detail.field_required')}` : ''}
              kInputName="address"
              kStartAdornment={
                <InputAdornment position="start">
                  <IconButton onClick={() => openMap()} edge="start"><LocationOnRounded sx={{ color: LmiTheme.light.systemBackground.primary }} /></IconButton>
                </InputAdornment>
              }
              kEndAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => openMap()} edge="end"><EditRounded sx={{ color: LmiTheme.light.accent }} /></IconButton>
                </InputAdornment>
              }
            />
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={1}
              py={3}
            >
              <Typography fontSize="body1.fontSize" color={LmiTheme.light.systemBackground.secondary} fontWeight={500}>
                {changeExactNameLocation}
              </Typography>
              <IOSSwitch
                name="exactAddress"
                checked={itemInfo?.exactAddress}
                onChange={(e) => { onChange(e); }}
              />
            </Stack >
            <Typography fontSize="subtitle2.fontSize" color={LmiTheme.light.systemBackground.tertiary}>
              {t('item_detail.hide_location_txt')}
            </Typography>
          </Grid >
          <Grid item xs={12} sm={6}>
            <Box mt={4}>
              {
                itemInfo
                && (
                  <StaticMap
                    kExactLocation={itemInfo?.exactAddress}
                    kLat={
                      !state.kItemIsNew
                        ? Number(itemInfo?.lat)
                        : (!addressHasChanged ? usrLat : itemInfo?.lat)
                    }
                    kLng={
                      !state.kItemIsNew
                        ? Number(itemInfo?.lon)
                        : (!addressHasChanged ? usrLng : itemInfo?.lon)
                    }
                  />
                )
              }
            </Box>
          </Grid>
        </Grid>
      </Box >
      {isLoaded && openChangeAddress ?
        <DialogCommon
          kTitle={`${t('add_item_page.location')}${t(`add_item_page.${titleHolder}`)}`}
          kSubTitle={`${t('add_item_page.select_location')}${t(`add_item_page.${titleHolder}`)}`}
          kMaxWidth="md"
          kBorderRadius={isMobile ? 0 : '10px'}
          kAlignBtn='center'
          kKeepBtnSize
          kDisableContentPadding={isMobile}
          kSlideTransition={isMobile}
          kDisableContentPaddinginY={isMobile}
          kOpenDialogLists={openChangeAddress}
          kOnClose={() => { cancelCloseMap(); }}
          kOnPrimary={() => { setOpenChangeAddress(!openChangeAddress); setAddressHasChanged(true) }}
          kOnSecondary={() => { cancelCloseMap(); }}
          kContent={
            (<SuggestedMap
              state={{
                address: itemInfo?.address ?? '',
                lat: itemInfo?.lat,
                long: itemInfo?.lon,
                typeItem: itemInfo?.idCatTypeItem ?? 3,
              }}
              usrLat={usrLat}
              usrLng={usrLng}
              userLatLng={{ lat: usrLat, lng: usrLng }}
              funUpdateInfo={updateMapa}
              kDisableInsideInput
            />)}
        />
        : <></>}
    </>
  );
}

export default ItemBasicInformation