/* eslint-disable max-lines */
/* eslint-disable no-nested-ternary */
import {
  Card, Box, Stack, Button, Divider, Typography, Backdrop, CircularProgress, useTheme, useMediaQuery, Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useContext, useEffect, useState, useRef,
} from 'react';
import { toast } from 'react-toastify';
import Compressor from 'compressorjs';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import {
  BannerCookies, DialogCommon, SectionTitle, LmiTheme, LoadingLayout, EmptyState,
} from '../../../../components/Common';
import { ActivityDetailModel } from './ActivityDetail.models';
import ActivityDetailStyle from './ActivityDetail.style';
import ActivityCancel from './Components/ActivityCancel/ActivityCancel';
import ActivityDelivery from './Components/ActivityDelivery/ActivityDelivery';
import ActivityIdReservation from './Components/ActivityIdReservation/ActivityIdReservation';
import ActivityInstructions from './Components/ActivityInstructions/ActivityInstructions';
import ActivityLocation from './Components/ActivityLocation/ActivityLocation';
import ActivityPayment from './Components/ActivityPayment/ActivityPayment';
import ActivityReservaton from './Components/ActivityReservation/ActivityReservaton';
import ActivityReview from './Components/ActivityReview/ActivityReview';
import ActivityReviewModal from './Components/ActivityReviewModal/ActivityReviewModal';
import ActivityScheduled from './Components/ActivityScheduled/ActivityScheduled';
import ActivityStatus from './Components/ActivityStatus/ActivityStatus';
import ActivitySummary from './Components/ActivitySummary/ActivitySummary';
import ActivitySwiper from './Components/ActivitySwiper/ActivitySwiper';
import ActivityUser from './Components/ActivityUser/ActivityUser';
import ActivityContext from '../../../../context/activity/activityContext';
import activityActionsContext from '../../../../context/activity/actions/activityActionsContext';
import { LocationFullModel } from '../../../../context/home/models/locationFullModel';
import ActivityProgressModal from './Components/ActivityProgressModal/ActivityProgressModal';
import ActivityTrackingModal from './Components/ActivityTrackingModal/ActivityTrackingModal';
import { ReactComponent as RoadieIcon } from '../../../../assets/icons/lmi_roadie_icon.svg';
import NewIndOrBusPayout from '../../ProfilePrivate/Components/TabPanelPayoutAccount/Forms/NewIndOrBusPayout';
import payoutAccountContext from '../../../../context/payoutAccount/payoutAccountContext';
import shuftiProContext from '../../../../context/shuftiPro/shuftiProContext';
import ImagesGridList from '../../../../components/Common/ImagesGridList/ImagesGridList';
import PickUpDropOffPicturesContext from '../../../../context/activity/pickUpDropOffPictures/pickUpdropOffPicturesContext';
import publicProfileContext from '../../../../context/publicProfile/publicProfileContext';
import deliveryActivityContext from '../../../../context/deliveryActivity/deliveryActivityContext';
import empty_lend from '../../../../assets/empty_state/empty_lend.png';

declare const window: any;

export default function ActivityReservationDetail(props: ActivityDetailModel) {
  const childRef: any = useRef();
  const [isEmptyDetail] = useState(false);
  const [openVerify, setOpenVerify] = useState(false);
  const [openProgress, setOpenProgress] = useState(false);
  const [openReview, setOpenReview] = useState(false);
  const [openReject, setOpenReject] = useState(false);
  const [openPicturesModal, setOpenPicturesModal] = useState(false);
  const [loaderOver, setLoaderOver] = useState(false);

  const [pickupImages, setPickupImages] = useState<any[]>([]);

  const [pickupVideos, setPickupVideos] = useState<any[]>([]);

  const [locationModel, setLocationModel]: any = useState();
  const [openTracking, setOpenTracking] = useState(false);
  const [openApproveReservation, setOpenApproveReservation] = useState(false);
  const [openAddPayOutDialog, setOpenAddPayOutDialog] = useState(false);
  const [progressStep, setProgressStep] = useState(1);
  const [image, setImage] = useState<File>();
  const [video, setVideo] = useState<File>();

  const activityContext = useContext(ActivityContext);
  const activityActionContext = useContext(activityActionsContext);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isLoadingVideo, setIsLoadingVideo] = useState(false);

  const [openShuftiIframe, setOpenShuftiIframe] = useState(false);
  const clickCloseShuftiIframe = () => setOpenShuftiIframe(false);

  const pProfileContext = useContext(publicProfileContext);
  const {
    privateProfileInfo,
  }: any = pProfileContext;

  const {
    updatePickUpPictures,
    updatePickUpVideos,
    pickUpPictures,
    pickUpVideos,
    convertVideoInBackend,
    videoConverted,
  }: any = useContext(PickUpDropOffPicturesContext);

  const finishVerification = () => {
    checkConfirmationStatusId(setTaskDone, privateProfileInfo?.email, privateProfileInfo?.userName, openedActivityItem);
    clickCloseShuftiIframe();
  };

  const pShuftiProContext = useContext(shuftiProContext);
  const {
    shuftiUrl,
  }: any = pShuftiProContext;

  const {
    openedActivityItem, // sets notif as read// ActivityActionssetNotifOpened
    rentalSummary,
    pickUpImages,
    renterLenderInfo,
    getActivityExtraInfo,
    userOwnInfo,
    requiresRenterIdVerification,
    rentalAccessories,
    rentalReviewComment,
    rentalPaymentMethod,
    termsAndConditions,
    dropOffImages,
    loading,
    checkConfirmationStatusId,
    canCancel,
    itemDeleted,
  }: any = activityContext;

  useEffect(() => {
    getActivityExtraInfo(openedActivityItem);
  }, []);

  const {
    activityValidatesApproved,
    message,
    rejectReason,
    taskEnded,
    setNewDelivery,
    approveNewDeliveryTime,
    diffRoadieMessage,
    cancelReservationPressed,
    rejectFailure,
    rejectFailureMessage,
    loadingActAction,
    setTaskDone,
    uploadPictureError,
    saveActivityReview,
    saveActivityPictures,
    saveActivityVideos,
    setReviewComment,
    setLoadingFalse,
    clearSetNewDelivery,
  }: any = activityActionContext;

  const pPyaoutContext = useContext(payoutAccountContext);
  const {
    payoutAccountDetails,
    checkNewPayoutValidations,
    changedPayoutFields,
    arePayoutFieldsValidated,
    createPayoutAccount,
    updatePayoutAccount,
    payoutUpdated,
  }: any = pPyaoutContext;

  const deliveryActivityContextLog = useContext(deliveryActivityContext);
  const { collectShipment }: any = deliveryActivityContextLog;

  useEffect(() => {
    if (arePayoutFieldsValidated) {
      if (payoutAccountDetails?.isDebitCar && payoutAccountDetails?.expiration?.length >= 4) {
        const splitExpire = payoutAccountDetails.expiration.split('/');
        const month = splitExpire[0];
        const year = splitExpire[1];
        const arrToSend = {
          ...payoutAccountDetails,
          expirationMonth: month,
          expirationYear: year,
        };
        if (payoutAccountDetails.accId != '') {
          updatePayoutAccount(arrToSend);
        } else {
          createPayoutAccount(arrToSend);
        }
      } else {
        const arrToSend = {
          ...payoutAccountDetails,
          expirationMonth: '',
          expirationYear: '',
        };
        if (payoutAccountDetails.accId != '') {
          updatePayoutAccount(arrToSend);
        } else {
          createPayoutAccount(arrToSend);
        }
      }
    }
  }, [changedPayoutFields]);

  useEffect(() => {
    if (openedActivityItem) {
      // eslint-disable-next-line func-names
      (async function fetchActivityInfo() {
        const _locationModel: LocationFullModel = {
          lat: parseFloat(
            openedActivityItem.exchangeLat.length > 0
              ? openedActivityItem.exchangeLat
              : openedActivityItem.lat,
          ),
          lon: parseFloat(
            openedActivityItem.exchangeLon.length > 0
              ? openedActivityItem.exchangeLon
              : openedActivityItem.lon,
          ),
          street:
            openedActivityItem?.exchangeAddress?.length > 0
              ? openedActivityItem.exchangeAddress
              : openedActivityItem.addressComplete.trim().length > 0
                ? openedActivityItem.addressComplete.trim()
                : openedActivityItem.address.trim(),
        };

        switch (openedActivityItem.notifActionType) {
          case 'approve':
          case 'new_rental':
            setProgressStep(0);
            break;
          case 'approved':
          case 'pickup':
            setProgressStep(1);
            break;
          case 'dropoff':
            setProgressStep(2);
            break;
          case 'review':
            setProgressStep(openedActivityItem.isReviewed ? 5 : 4);
            break;
          default:
            break;
        }

        setLocationModel(_locationModel);
        setIsCancelled(
          openedActivityItem.isCancelled
          || openedActivityItem.isCancelledRenter
          || openedActivityItem.isCancelledLender,
        );
      }());
    }
  }, [openedActivityItem]);

  useEffect(() => {
    if (message) {
      toast.error(message);
    }
  }, [message]);

  useEffect(() => {
    if (taskEnded) handleTaskEnded();
    if (setNewDelivery && !openApproveReservation) handleSetNewDelivery();
    if (rejectFailure) handleRejectFailure();
    if (payoutUpdated) handlePayoutUpdated();
    if (loadingActAction) handleLoadingActAction();
    if (uploadPictureError) handleUploadPictureError();
  }, [taskEnded, setNewDelivery, rejectFailure, uploadPictureError, payoutUpdated, loadingActAction]);

  const handleTaskEnded = () => {
    setOpenReject(false);
    setOpenCancel(false);
    setOpenApproveReservation(false);
    setOpenReview(false);
    setOpenPicturesModal(false);
    closeModalImages();
    setLoaderOver(false);
  };

  const handleSetNewDelivery = () => {
    setOpenApproveReservation(true);
    // clearSetNewDelivery set true when a new roadie time will be added
    // it needs to remove a flag in order to prevent to open the modal back
    clearSetNewDelivery();
  };

  const handleRejectFailure = () => {
    setOpenCancel(false);
    toast.error(rejectFailureMessage, {
      toastId: 'roadie_error_3',
    });
  };

  const handlePayoutUpdated = () => {
    setOpenAddPayOutDialog(false);
    setTaskDone();
  };

  const handleLoadingActAction = () => {
    setOpenCancel(false);
    setOpenReject(false);
    setOpenReview(false);
  };

  const handleUploadPictureError = () => {
    setLoaderOver(false);
    closeModalImages();
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleButtonStatus = (status: boolean) => {
    setIsButtonDisabled(status);
  };

  useEffect(() => {
    updatePickUpVideos(videoConverted);
  }, [videoConverted]);

  const closeModals = () => {
    setOpenReject(false);
    setOpenCancel(false);
    setOpenApproveReservation(false);
    setOpenReview(false);
    setOpenPicturesModal(false);
    closeModalImages();
    setLoaderOver(false);
    setLoadingFalse();
  };

  const [openCancel, setOpenCancel] = useState(false);

  const cookieStorage = {
    getItem: (item: any) => {
      const cookies = document.cookie
        .split(';')
        .map((cookie) => cookie.split('='))
        .reduce((acc, [key, value]) => ({ ...acc, [key.trim()]: value }), {});
      return cookies[item];
    },
    setItem: (item: any, value: any) => {
      const d = new Date();
      const exdays = 7;
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      const expires = `expires=${d.toUTCString()}`;
      document.cookie = `${item}=${value};${expires}`;
    },
  };

  const openSupport = () => {
    if (cookieStorage.getItem('consentNecessaryCookies') || cookieStorage.getItem('consentNecessaryCookies') == false) {
      window.zE('webWidget', 'toggle');
    } else {
      childRef.current.refToggleMessagesModal();
    }
  };

  const clickSavePayout = () => {
    checkNewPayoutValidations(true);
  };

  const toastPhoneNumber = () => {
    const ladaNumber = collectShipment?.driver?.phone.substring(0, 3);
    const firstNumbers = collectShipment?.driver?.phone.substring(3, 6);
    const lastNumbers = collectShipment?.driver?.phone.substring(6);
    navigator.clipboard.writeText(collectShipment?.driver?.phone);
    toast.info(`${t('activity.delivery.please_call')} (${ladaNumber})-${firstNumbers}-${lastNumbers}`);
  };

  const closeReviewModal = () => {
    setReviewComment('');
    setOpenReview(false);
  };

  const removePictureFromArray = (arrPictures: any) => {
    updatePickUpPictures(arrPictures);
  };

  const removeVideoFromArray = (arrPictures: any) => {
    updatePickUpVideos(arrPictures);
  };

  const swapImage = (e: any) => {
    setPickupImages(e);
    updatePickUpPictures(e);
  };

  const swapVideo = (e: any) => {
    setPickupVideos(e);
    updatePickUpVideos(e);
  };

  const onImageDropped = (e: any) => {
    const allowedImages = 6 - pickupImages.length - e.length;
    if (allowedImages >= 0) {
      Array.from(e).forEach((file: any) => {
        // eslint-disable-next-line no-new
        new Compressor(file, {
          convertTypes: [
            'image/png',
            'image/webp',
            'image/bmp',
            'image/tiff',
          ],
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          convertSize: 1000,
          maxWidth: 4096,
          maxHeight: 4096,
          minWidth: 1080,
          minHeight: 1920,
          success: (compressedResult) => {
            const reader = new FileReader();
            reader.onloadend = () => {
              const newPics = pickUpPictures;
              newPics.push(reader.result as string);
              updatePickUpPictures(newPics);
            };
            reader.readAsDataURL(compressedResult);
          },
        });
      });
    } else {
      toast.error(t('add_item_page.6_pictures'));
    }
  };

  const onVideoDropped = (e: any) => {
    const allowedVideos = 2 - (pickupVideos?.length ?? 0) - (e?.length ?? 0);
    if (allowedVideos >= 0) {
      Array.from(e).forEach((file: any) => {
        const sizeInMB = file.size / (1024 * 1024);
        if (sizeInMB > 512) {
          toast.error(t('add_item_page.video_size_alert'));
          return;
        }
        const reader = new FileReader();

        reader.onloadend = async () => {
          if (file.type != 'video/quicktime') {
            const newVids = pickupVideos;
            const objNewVideo = {
              base64: reader.result as string,
              name: `vid_${uuid()}_${file.name}`,
              file, // Almacenar el archivo completo aquí
            };
            pickupVideos?.push(objNewVideo);
            updatePickUpVideos(newVids);
          } else if (file.type == 'video/quicktime') {
            if (sizeInMB < 150) {
              setIsLoadingVideo(true);
              await convertVideoInBackend(file, sizeInMB, t);
              setIsLoadingVideo(false);
            } else {
              toast.warning(t('add_item_page.mov_video_size_alert'));
            }
          }
        };
        reader.readAsDataURL(file);
      });
    } else {
      toast.error(t('add_item_page.2_videos'));
    }
  };

  const onImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file && (file.type.substring(0, 5) == 'image')) {
      setImage(file);
    } else if (file.type.substring(0, 5) == 'video') {
      setVideo(file);
    } else {
      // setImage();
    }
  };

  useEffect(() => {
    if (image) {
      const reader = new FileReader();
      reader.onloadend = () => {
        pickupImages.push(reader.result as string);
        setPickupImages(pickupImages);
      };
      reader.readAsDataURL(image);
    } else if (video) {
      const reader = new FileReader();
      reader.onloadend = () => {
        // uploadItemVideo();
      };
      reader.readAsArrayBuffer(video);
    } else {
      //
    }
  }, [image, video]);

  const submitActivityPictures = async (e: any) => {
    let typeOfPictures = 'pickup';
    if (e.notifActionType == 'dropoff' || openedActivityItem?.idCatLndRentalStatus === 5) {
      typeOfPictures = 'dropoff';
    }
    if (pickUpPictures.length == 0) {
      toast.info('Please add a picture');
      return;
    }
    setLoaderOver(true);
    try {
      await saveActivityPictures(e, pickUpPictures, typeOfPictures);
      await saveActivityVideos(e, pickUpVideos, typeOfPictures);
      setPickupImages([]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoaderOver(false);
      setOpenPicturesModal(false);
    }
  };

  const closeModalImages = () => {
    if (!isLoadingVideo) {
      setOpenPicturesModal(false);
      updatePickUpPictures([]);
    }
  };

  function CancelRejectTitle(notifDetail: any, isReject: boolean) {
    const { t } = useTranslation('global');
    switch (notifDetail?.notifActionType) {
      case 'approve_buy':
        return isReject ? t('activity_detail.reject_purchase') : t('activity_detail.cancel_purchase');
      case 'new_buy':
        return isReject ? t('activity_detail.reject_purchase') : t('activity_detail.cancel_purchase');
      default:
        return isReject ? t('activity_detail.reject_reservation') : t('activity_detail.cancel_reservation');
    }
  }

  const uiTheme = useTheme();
  const isMobile = useMediaQuery(uiTheme.breakpoints.down('md'));

  const { t } = useTranslation('global');

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 500 }}
        open={loaderOver}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {(!loading && !loadingActAction)
        ? (

          <Card elevation={0} sx={ActivityDetailStyle}>
            {isEmptyDetail || !openedActivityItem
              ? (
                <Box className="lmiw-empty-detail">
                  {t('activity_detail.no_activity')}
                </Box>
              ) : (
                itemDeleted
                  ? (
                    <Grid item xs={12} sx={{ width: '100%', height: { xs: 'auto', md: '70vh' } }}>

                      <EmptyState
                        kImage={empty_lend}
                        kTitle={t('activity_detail.unavailableItemTitle')}
                        kHeightAuto
                        kDescription={t('activity_detail.unavailableItemDescription')}
                      />
                    </Grid>
                  )
                  : (
                    <>
                      <ActivityIdReservation kId={openedActivityItem?.reservationCode} />
                      <Box className="lmiw-detail-content">
                        <Stack
                          direction="column"
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={2}
                          px={2}
                          pb={2}
                        >
                          <ActivityStatus
                            kItemInfo={openedActivityItem}
                            kUserInfo={renterLenderInfo}
                            kPickUpImages={pickUpImages}
                            kDropOffImages={dropOffImages}
                            kActivityApprove={() => activityValidatesApproved(true, openedActivityItem, locationModel, '')}
                            kActivityReject={() => setOpenReject(true)}
                            kActivityVerify={() => setOpenVerify(true)}
                            kActivityReview={() => setOpenReview(true)}
                            kActivityProgress={() => setOpenProgress(true)}
                            kDescStatus=""
                            kopenModalPictures={() => setOpenPicturesModal(true)}
                          />
                          {(openedActivityItem)
                          && (
                            <ActivityUserActions
                              notifDetail={openedActivityItem}
                              userInfo={userOwnInfo}
                              requiresRenterIdVerification={requiresRenterIdVerification}
                              setOpenAddPayOutDialog={setOpenAddPayOutDialog}
                              setOpenShuftiIframe={setOpenShuftiIframe}
                            />
                          )}
                          {(openedActivityItem && rentalReviewComment)
                          && <ActivityReview reviewComment={rentalReviewComment} notifDetail={openedActivityItem} />}
                          <ActivityReservaton itemInfo={openedActivityItem} accessories={rentalAccessories} activitySummary={rentalSummary} />
                          {(termsAndConditions?.length > 0)
                          && <ActivityInstructions reqs={termsAndConditions} />}
                          <ActivityScheduled notifDetail={openedActivityItem} />
                          {
                          openedActivityItem?.isLenderDelivery
                            ? (<ActivityDelivery notifDetail={openedActivityItem} kType="PickUpByLender" kOpenTracking={() => setOpenTracking(true)} />)
                            : null
                            // openedActivityItem?.isRoadieDelivery
                            //  ? (<ActivityDelivery notifDetail={openedActivityItem} kType="Roadie" kOpenTracking={() => setOpenTracking(true)} />)
                            //  : null
                        }
                          {(openedActivityItem && rentalAccessories)
                          && (
                            <ActivitySummary
                              accessoriesQty={rentalAccessories?.length}
                              activitySummary={rentalSummary}
                              notifDetail={openedActivityItem}
                            />
                          )}
                          {(openedActivityItem && rentalPaymentMethod.length > 0)
                          && <ActivityPayment paymentMethod={rentalPaymentMethod} />}
                          <ActivityUser
                            notifDetail={openedActivityItem}
                            userInfo={renterLenderInfo}
                            kOpenChat={() => props.kOpenChat()}
                            kType="verified"
                          />
                          {(!openedActivityItem?.isReviewed && !openedActivityItem?.isLenderDelivery
                            && !openedActivityItem?.isRoadieDelivery) && (<ActivityLocation notifDetail={openedActivityItem} />)}

                          {/* Activity user actions as in app */}

                          {(openedActivityItem?.notifActionType != 'cancelled' && openedActivityItem.notifActionType != 'rejected' && pickUpImages?.length > 0)
                          && <ActivitySwiper kType="PickUp" kImage={pickUpImages} />}

                          {(openedActivityItem?.notifActionType != 'cancelled' && openedActivityItem.notifActionType != 'rejected' && dropOffImages?.length > 0)
                          && <ActivitySwiper kType="DropOff" kImage={dropOffImages} />}
                          {
                          openedActivityItem?.notifActionType != 'rejected'
                          && openedActivityItem.notifType === 'LEND' && canCancel && !isCancelled && openedActivityItem.isRent! && openedActivityItem.isLenderApproved! && (
                            <Button className="lmiw-detail-btn" size="large" variant="text" onClick={() => setOpenCancel(true)}>
                              {t('activity_detail.want_cancel')}
                            </Button>
                          )
                        }

                          {openedActivityItem?.notifActionType != 'rejected' && openedActivityItem.notifType !== 'LEND' && canCancel && !isCancelled && (
                          <Button className="lmiw-detail-btn" size="large" variant="text" onClick={() => setOpenCancel(true)}>
                            {t('activity_detail.want_cancel')}
                          </Button>
                          )}

                          <Button className="lmiw-detail-btn" size="large" variant="text" onClick={() => openSupport()}>{t('activity_detail.contact_support')}</Button>
                          <BannerCookies ref={childRef} kShowBanner={false} />
                        </Stack>
                      </Box>
                    </>
                  )
              )}

          </Card>
        )
        : (<LoadingLayout />)}
      <DialogCommon
        kTitle={CancelRejectTitle(openedActivityItem, true)}
        kMaxWidth="sm"
        kOpenDialogLists={openReject}
        kAlignBtn={isMobile ? 'center' : 'flex-end'}
        kBorderRadius={!isMobile ? '10px' : 0}
        kSlideTransition={isMobile}
        kMaxHeight="600px"
        kKeepBtnSize
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kOnClose={() => setOpenReject(false)}
        kContent={openReject ? <ActivityCancel kMessage={t('activity_detail.reason_rejection')} kLenderRenter={openedActivityItem} /> : null}
        kDisableContentPadding
        kOnPrimary={() => activityValidatesApproved(false, openedActivityItem, locationModel, rejectReason)}
        kOnSecondary={() => setOpenReject(false)}
        kDisableBtnSave={rejectReason == null || rejectReason == ''}
      />
      <DialogCommon
        kTitle={t('activity_detail.approve_reservation')}
        kMaxWidth="sm"
        kOpenDialogLists={openApproveReservation}
        kAlignBtn={isMobile ? 'center' : 'flex-end'}
        kBorderRadius={!isMobile ? '10px' : 0}
        kSlideTransition={isMobile}
        kKeepBtnSize
        kOnClose={() => closeModals()}
        kOnSecondary={() => closeModals()}
        kOnPrimary={() => approveNewDeliveryTime(openedActivityItem, locationModel)}
        kDisableActions={false}
        kContent={
          (
            <Typography>
              {diffRoadieMessage}
            </Typography>
          )
        }
      />
      <DialogCommon
        kTitle={CancelRejectTitle(openedActivityItem, false)}
        kMaxWidth="sm"
        kOpenDialogLists={openCancel}
        kAlignBtn={isMobile ? 'center' : 'flex-end'}
        kBorderRadius={!isMobile ? '10px' : 0}
        kSlideTransition={isMobile}
        kKeepBtnSize
        kOnClose={() => setOpenCancel(false)}
        kDisableContentPadding
        kContent={openCancel ? <ActivityCancel kMessage={t('activity_detail.reason_cancel')} kLenderRenter={openedActivityItem} /> : null}
        kOnPrimary={() => cancelReservationPressed(rejectReason, openedActivityItem)}
        kOnSecondary={() => setOpenCancel(false)}
        kDisableBtnSave={rejectReason == null || rejectReason == ''}
      />
      <DialogCommon
        kTitle={t('activity_detail.review_experience')}
        kMaxWidth="sm"
        kAlignBtn={isMobile ? 'center' : 'flex-end'}
        kOpenDialogLists={openReview}
        kBorderRadius={!isMobile ? '10px' : 0}
        kSlideTransition={isMobile}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kKeepBtnSize
        kMaxHeight={isMobile ? '680px' : '700px'}
        kOnClose={() => closeReviewModal()}
        kDisableContentPadding
        kDisableBtnSave={isButtonDisabled}
        kContent={<ActivityReviewModal notifDetail={openedActivityItem} onButtonStatusChange={handleButtonStatus} />}
        kOnPrimary={() => saveActivityReview(openedActivityItem)}
        kPrimaryBtnText={t('activity_detail.submit')}
      />
      <DialogCommon
        kTitle={openedActivityItem?.notifActionType == 'pickup' ? t('activity_detail.upload_pickup') : t('activity_detail.upload_dropoff')}
        kMaxWidth="sm"
        kAlignBtn={isMobile ? 'center' : 'flex-end'}
        kBorderRadius={!isMobile ? '10px' : 0}
        kSlideTransition={isMobile}
        kKeepBtnSize
        kOpenDialogLists={openPicturesModal}
        kOnClose={() => closeModalImages()}
        kDisableActions={isLoadingVideo}
        kDisableBtnSave={isLoadingVideo}
        kContent={!isLoadingVideo ? (
          <ImagesGridList
            isActivity
            itemImages={pickUpPictures}
            itemVideos={pickUpVideos}
            removeVideoFromArray={removeVideoFromArray}
            swapVideo={swapVideo}
            removePictureFromArray={removePictureFromArray}
            onImageChange={onImageChange}
            swapImage={swapImage}
            onImageDropped={onImageDropped}
            onVideoDropped={onVideoDropped}
            isBlocked={false}
            isSave
          />
        ) : <LoadingLayout />}
        kOnPrimary={() => submitActivityPictures(openedActivityItem)}
        kPrimaryBtnText={t('activity_detail.submit')}
      />
      <DialogCommon
        kTitle={t('activity_detail.view_progress')}
        kMaxWidth="sm"
        kOpenDialogLists={openProgress}
        kSlideTransition={isMobile}
        kKeepBtnSize
        kMaxHeight="420px"
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kBorderRadius={isMobile ? 0 : '10px'}
        kOnClose={() => setOpenProgress(false)}
        kDisableActions
        kContent={<ActivityProgressModal kActiveStep={progressStep} />}
      />

      <DialogCommon
        kTitle={t('activity_detail.id_verification')}
        kMaxWidth="sm"
        kAlignBtn={isMobile ? 'center' : 'flex-end'}
        kBorderRadius={!isMobile ? '10px' : 0}
        kSlideTransition={isMobile}
        kKeepBtnSize
        kOpenDialogLists={openVerify}
        kOnClose={() => setOpenVerify(false)}
        kDisableActions
        kContent={<h5>{t('activity_detail.verification_stripe')}</h5>}
      />

      <DialogCommon
        kTitle={t('activity_detail.id_verification')}
        kMaxWidth="md"
        kAlignBtn={isMobile ? 'center' : 'flex-end'}
        kBorderRadius={!isMobile ? '10px' : 0}
        kSlideTransition={isMobile}
        kKeepBtnSize
        kOpenDialogLists={openShuftiIframe}
        kOnClose={() => { finishVerification(); }}
        kOnSecondary={() => { finishVerification(); }}
        kOnPrimary={() => { finishVerification(); }}
        kDisableActions
        kContent={
          (
            <Card sx={{ height: 600, width: 800 }}>
              <iframe
                title="shufti"
                height="100%"
                width="100%"
                src={shuftiUrl}
                id="shuftipro-iframe"
                allow="camera"
                frameBorder="0"
              />
            </Card>
          )
        }
      />
      <DialogCommon
        kTitle={t('activity_detail.add_payout')}
        kMaxWidth="sm"
        kAlignBtn={isMobile ? 'center' : 'flex-end'}
        kBorderRadius={!isMobile ? '10px' : 0}
        kSlideTransition={isMobile}
        kKeepBtnSize
        kOpenDialogLists={openAddPayOutDialog}
        kOnClose={() => setOpenAddPayOutDialog(false)}
        kOnSecondary={() => setOpenAddPayOutDialog(false)}
        kOnPrimary={() => clickSavePayout()}
        kContent={<NewIndOrBusPayout />}
      />

      {openedActivityItem?.isRoadieDelivery ? (
        <DialogCommon
          kTitle={t('activity_detail.tracking')}
          kSubTitle={(
            <Stack direction="row" justifyContent="flex-start" alignItems="center" sx={{ color: LmiTheme.light.systemBackground.secondary }}>
              <Typography fontSize={14} sx={{ mr: '5px' }}>{t('activity_detail.delivery_between')}</Typography>
              <Typography fontSize={14} fontWeight="500">
                {openedActivityItem?.isRoadieDelivery
                  ? `${moment(collectShipment?.deliverBetween?.start, ['HH:mm']).format('h:mm A')} - ${moment(collectShipment?.deliverBetween?.end, ['HH:mm']).format('h:mm A')}`
                  : '12:30 PM - 7:00 PM'}
              </Typography>
            </Stack>
        )}
          kMaxWidth="sm"
          kHeight="500px"
          kOpenDialogLists={openTracking}
          kOnClose={() => setOpenTracking(false)}
          kDisableContentPadding
          kSlideTransition={isMobile}
          kKeepBtnSize
        // kMaxHeight={isMobile ? '260px' : '300px'}
          kMaxHeight={isMobile ? '520px' : '600px'}
          kFixedDialog={0}
          kFixedDialogPosition="fixed"
          kBorderRadius={isMobile ? 0 : '10px'}
          kContent={<ActivityTrackingModal />}
          kOnSecondary={() => {
            toastPhoneNumber();
          }}
          kAlignBtn="space-between"
          kSecondaryBtnText={t('activity_detail.contact')}
          kElementAction={(
            <Stack direction="row" justifyContent="flex-start" alignItems="center">
              <RoadieIcon width={42} height={42} style={{ marginRight: 16 }} />
              <Stack direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{ lineHeight: 1 }}>
                <Typography fontSize={18} fontWeight="bold">Roadie</Typography>
                <Typography
                  fontSize={16}
                  fontWeight="bold"
                  sx={{ color: LmiTheme.light.systemBackground.secondary, mt: '-10px' }}
                >

                  {collectShipment?.driver?.vehicleType}
                  ,
                  {collectShipment?.driver?.vehicleColor}
                </Typography>
              </Stack>
            </Stack>
        )}
        />
      ) : (<Box />)}

    </>
  );
}
function ActivityUserActions({
  notifDetail, userInfo, requiresRenterIdVerification, setOpenAddPayOutDialog, setOpenShuftiIframe,
}: any) {
  console.log(ActivityLocationActions);
  return (
    <>
      {(notifDetail.notifActionType != 'cancelled' && notifDetail.notifActionType != 'rejected')
        ? (
          <Box>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1}>
              <LenderPendingActionsTitle notifDetail={notifDetail} userInfo={userInfo} requiresRenterIdVerification={requiresRenterIdVerification} />
            </Stack>
            <Stack>
              <LenderPendingActions
                setOpenAddPayOutDialog={setOpenAddPayOutDialog}
                setOpenShuftiIframe={setOpenShuftiIframe}
                notifDetail={notifDetail}
                userInfo={userInfo}
                requiresRenterIdVerification={requiresRenterIdVerification}
              />
            </Stack>
            <Divider />
          </Box>
        )
        : <Box />}
    </>
  );
}

function LenderPendingActionsTitle({ userInfo, notifDetails, requiresRenterIdVerification }: any) {
  const { t } = useTranslation('global');

  const isVerified = userInfo?.lenderVerified
    || userInfo?.lenderVerifiedShufti
    || userInfo?.lenderVerifiedStripe
    || userInfo?.renterVerified
    || userInfo?.renterVerifiedShufti
    || userInfo?.renterVerifiedStripe
    || !requiresRenterIdVerification;

  const hasPayOutAccount = userInfo?.hasPayOutAccount;
  const notifType = notifDetails?.notifType;

  switch (true) {
    case !isVerified && notifType === 'LEND':
    case isVerified && !hasPayOutAccount && notifType === 'LEND':
      return (
        <SectionTitle kFontSize={18} kMarginLeft={0} kMarginRight={0} kMarginTop={2} kMarginBottom={0} kTitle={t('activity_detail.complete_txt')} />
      );
    // case !isVerified && notifType !== 'LEND':
    //  return (
    //    <SectionTitle kMarginLeft={0} kMarginRight={0} kMarginTop={0} kMarginBottom={0} kTitle={t('activity_detail.complete_txt')} />
    //  );
    default:
      return <Box />;
  }
}

function LenderPendingActions({
  userInfo, notifDetail,
  setOpenAddPayOutDialog,
  // setOpenShuftiIframe,
  // requiresRenterIdVerification,
}: any) {
  let _verifOrPayOutPending = false;
  if (!userInfo?.lenderVerified
    || !userInfo?.lenderVerifiedShufti
    || !userInfo?.hasPayOutAccount) {
    _verifOrPayOutPending = true;
    console.log(_verifOrPayOutPending);
    // _isLocationShared = true;
  }

  const { t } = useTranslation('global');

  switch (notifDetail?.notifType) {
    case 'LEND':
      return (
        <>
          {/* {!(userInfo?.lenderVerified
            || userInfo?.lenderVerifiedShufti
            || userInfo?.lenderVerifiedStripe
            || userInfo?.renterVerified
            || userInfo?.renterVerifiedShufti
            || userInfo?.renterVerifiedStripe
            || !requiresRenterIdVerification
          ) && (<Button onClick={() => setOpenShuftiIframe(true)} className="lmiw-detail-btn" size="large" variant="contained">{t('activity_detail.verify_id')}</Button>)}
          */}
          <Box sx={{
            height: { xs: '10px', md: '10px' },
          }}
          />
          {
            (!userInfo?.hasPayOutAccount)
            && (<Button onClick={() => setOpenAddPayOutDialog(true)} className="lmiw-detail-btn" size="large" variant="contained">{t('activity_detail.setup_payout')}</Button>)
          }
        </>
      );
    case 'RENT':
      return (
        <>
          {/*
          {!(userInfo?.lenderVerified
            || userInfo?.lenderVerifiedShufti
            || userInfo?.lenderVerifiedStripe
            || userInfo?.renterVerified
            || userInfo?.renterVerifiedShufti
            || userInfo?.renterVerifiedStripe
            || !requiresRenterIdVerification
          ) && (<Button onClick={() => setOpenShuftiIframe(true)} className="lmiw-detail-btn" size="large" variant="contained">{t('activity_detail.verify_id')}</Button>)}
          */}
        </>
      );
    default:
      return <Box />;
  }
}

function ActivityLocationActions({ notifDetail, userInfo }: any) {
  console.log(userInfo, ActivityUserActions);
  return (
    <>
      {(notifDetail?.notifActionType != 'cancelled' && notifDetail?.notifActionType != 'rejected')
        ? (
          <Box className="lmiw-detail">
            <Stack direction="row" spacing={1} sx={{ minHeight: 40, display: { xs: 'none', md: 'flex' } }} px={2} py={1}>
              <LenderPendingActionsTitle notifDetail={notifDetail} userInfo={userInfo} />

              {
                (!notifDetail?.isReviewed && !notifDetail?.isLenderDelivery && notifDetail?.isRoadieDelivery)
                && LenderLocationPendingTitle
              }
              {
                (!notifDetail?.isReviewed && !notifDetail?.isLenderDelivery && notifDetail?.isRoadieDelivery)
                && LenderLocationPendingTitle
              }
            </Stack>
            <Stack>
              <LenderPendingActions notifDetail={notifDetail} userInfo={userInfo} />
            </Stack>
            <Divider />
          </Box>
        )
        : <Box />}
    </>
  );
}

function LenderLocationPendingTitle({ notifDetail }: any) {
  const { t } = useTranslation('global');
  if (notifDetail?.notifType == 'LEND') {
    return (
      <>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ minHeight: 40, display: { xs: 'none', md: 'flex' } }} px={2} py={1}>
          {/* Title, make list tile clickable */}
          <SectionTitle kMarginLeft={0} kMarginRight={0} kMarginTop={0} kMarginBottom={0} kTitle={t('activity.location.your_location')} />
          {/* trailing  */}
          {(notifDetail?.exchangeLat.length == 0) && <SectionTitle kMarginLeft={0} kMarginRight={0} kMarginTop={0} kMarginBottom={0} kTitle={t('global.change')} />}
        </Stack>
      </>
    );
  }
  return (
    <>
      {(notifDetail?.exchangeLat.length > 0) && <SectionTitle kMarginLeft={0} kMarginRight={0} kMarginTop={0} kMarginBottom={0} kTitle={t('activity.location.lender_location')} />}
    </>
  );
}
