/* eslint-disable max-lines */
import {
  Grid, Divider, Card, CardContent, Stack, IconButton, Box, Paper, Typography, useMediaQuery, useTheme, Avatar,
} from '@mui/material';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import IPData from 'ipdata';
import { useTranslation } from 'react-i18next';
import { ArrowBackIosRounded } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import Compressor from 'compressorjs';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import WarningIcon from '@mui/icons-material/Warning';
import ItemAccessories from '../Listing/Components/AddItem/Components/ItemAccessories/ItemAccessories';
import ItemRequirements from '../Listing/Components/AddItem/Components/ItemRequirements/ItemRequirements';
import ItemDelivery from '../Listing/Components/AddItem/Components/ItemDelivery/ItemDelivery';
import addEditItemContext from '../../../context/addEditItem/addEditItemContext';
import ImagesGridList from '../../../components/Common/ImagesGridList/ImagesGridList';
import BlockedCalendar from '../Listing/Components/AddItem/Components/BlockedCalendar/BlockedCalendar';
import { ContentLayout, DialogCommon, SectionTitle } from '../../../components/Common';
import HeaderTitle from '../../../components/Common/HeaderTitle/HeaderTitle';
import Heading from './Components/Heading/Heading';
import ListingStatus from '../Listing/Components/AddItem/Components/ListingStatus/ListingStatus';
import LoadingLayout from '../../../components/Common/LoadingLayout/LoadingLayout';
import AppBarSecondary from '../../../components/Layout/AppBarSecondary/AppBarSecondary';
import authContext from '../../../context/authentication/authContext';
import publicProfileContext from '../../../context/publicProfile/publicProfileContext';
import DialogListingCreated from './DialogListingCreated';
import { DialogLists } from '../../FrontOffice/Checkout/Components';
import ManageCirclesSection from '../Listing/Components/ManageCirclesSection/ManageCirclesSection';
// import IdVerificationSwitch from './Components/IdVerificationSwitch/IdVerificationSwitch';
import MaxRateSwitch from './Components/MaxRateSwitch/MaxRateSwitch';
import ItemBasicInformation from '../Listing/Components/AddItem/Components/ItemBasicInformation/ItemBasicInformation';
import DialogSetupPayout from './DialogSetupPayout';
import getPosition from '../../../utils/getElementPosition';
import payoutAccountContext from '../../../context/payoutAccount/payoutAccountContext';

export default function AddItem() {
  const {
    token,
  }: any = useContext(authContext);

  const history = useHistory();

  const mountedRef = useRef(false);

  const pProfileContext = useContext(publicProfileContext);

  const {
    privateProfileInfo,
    getPrivateProfileInfo,
  }: any = pProfileContext;

  const pPayoutContext = useContext(payoutAccountContext);
  const {
    payoutAccountDetails,
    getUserPayoutAccount,
  }: any = pPayoutContext;

  const state = history.location.state as {
    kFromDraft: boolean,
    kItemId: string,
    kItemIsNew: boolean,
    kItemIndex: number,
    updateSwiper: false,
    kShowDetailsMobile: false
  };

  if (!state) {
    mountedRef.current = false;
    window.location.replace('/listing');
    return null;
  }
  const currencySourceEnum = {
    PhoneNumber: 'PhoneNumber',
    Payout: 'Payout',
    IPData: 'IPData',
  };

  const addEditContextLog = useContext(addEditItemContext);
  const idCatLndItem = state && state.kItemIsNew ? 0 : state?.kItemId ?? 0;
  const [isBlocked, setIsBlocked] = useState(false);
  const [flagAdd, setFlagAdd] = useState(false);
  const [BtnSave, setBtnSave] = useState(false);
  const [openCongratulationsDialog, setOpenCongratulationsDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const [userCurrency, setUserCurrency] = useState('');
  const [isReset, setIsReset] = useState(false);
  const [currencySource, setCurrencySource] = useState(currencySourceEnum.PhoneNumber);
  const [canSaveDraftVideo, setCanSaveDraftVideo] = useState(true);
  const {
    getItemInfo,
    itemInfo,
    itemVideos,
    itemImages,
    userTerms,
    blockedIndDates,
    deletedVideos,
    deletedImages,
    itemAccessories,
    checkValidations,
    areFieldsValidated,
    updateItemVideos,
    updateDeletedVideos,
    updateItemIdVideo,
    updateDeletedImages,
    updateItemImages,
    updateItemIdImage,
    updateValidationStatus,
    isHourlyRental,
    isDailyRental,
    isWeeklyRental,
    isMonthlyRental,
    createUpdateItemInfo,
    deleteItem,
    resetItemInfo,
    updateItemInfo,
    updateDraftItemInfo,
    errors,
    cleanErrorsItem,
    convertVideoInBackend,
  }: any = addEditContextLog;

  useEffect(() => {
    if (localStorage.getItem('isSavingItem')) {
      localStorage.removeItem('isSavingItem');
      discardLocalStorage();

      history.push('/listing');
    }
  }, []);

  const clickCloseCongratulationsDialog = () => {
    setOpenCongratulationsDialog(false);
    history.push('/listing');
  };

  const [openRequireRenterId, setOpenRequireRenterId] = useState(false);
  const [hasMaxRate, setMaxRate] = useState(false);
  const [serviceMaxRateValue, setServiceMaxRateValue] = useState('');

  useEffect(() => {
    (async function fetchItemData() {
      cleanErrorsItem();

      if (Object.keys(privateProfileInfo).length === 0 || privateProfileInfo.length <= 3) {
        await getPrivateProfileInfo({ idCatLender: 0 });
      }

      _setCurrency();
      await getItemInfo(Number(idCatLndItem));
    }());
  }, []);

  useEffect(() => {
    (async function fetchPayoutAccount() {
      await getUserPayoutAccount();
    }());
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event: any) => {
      if (isBlocked) {
        event.preventDefault();
        event.returnValue = t('global.saving_item_alert');
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isBlocked]);

  useEffect(() => {
    if (privateProfileInfo?.idCatLndUser != null) {
      _setCurrency();
    }
  }, [privateProfileInfo]);

  const _setCurrency = () => {
    if (!token) {
      _getIpData();
    } else {
      const userHasPayOutAccount = payoutAccountDetails.accId != '';
      switch (userHasPayOutAccount) {
        case true:
          if (payoutAccountDetails.country == 'MX') {
            setUserCurrency(!state.kItemIsNew ? itemInfo?.currency : 'MXN');
          } else {
            setUserCurrency(!state.kItemIsNew ? itemInfo?.currency : 'USD');
          }
          break;
        case false:
          const userPhoneNumber = privateProfileInfo?.phoneNumber;
          if (userPhoneNumber?.length > 0) {
            const phoneCode = userPhoneNumber.slice(0, -10);
            if (phoneCode == '+52') {
              setUserCurrency(!state.kItemIsNew ? itemInfo?.currency : 'MXN');
            } else {
              setUserCurrency(!state.kItemIsNew ? itemInfo?.currency : 'USD');
            }
          } else {
            setUserCurrency(!state.kItemIsNew ? itemInfo?.currency : 'USD');
          }
          break;
        default:
          setUserCurrency(!state.kItemIsNew ? itemInfo?.currency : 'USD');
          break;
      }
      const _currencySource = privateProfileInfo.hasPayOutAccount
        ? currencySourceEnum.Payout
        : currencySourceEnum.PhoneNumber;

      setCurrencySource(_currencySource);
    }
  };

  const _getIpData = () => {
    const ipdata = new IPData(`${process.env.REACT_APP_IPDATA}`);
    ipdata.lookup().then((data) => {
      if (data.country_code == 'MX') {
        setUserCurrency('MXN');
      } else {
        setUserCurrency('USD');
      }
    });

    setCurrencySource(currencySourceEnum.IPData);
  };

  const saveImageDraft = (value: any) => {
    if (state.kItemIsNew) updateItemIdImage(value);
  };

  const saveVideoDraft = (value: any) => {
    if (state.kItemIsNew) updateItemIdVideo(value);
  };

  useEffect(() => {
    if (errors) {
      const { position, type } = getPosition(errors);
      if (Object.keys(errors).length) {
        const { x, y } = position;
        if (type !== '') {
          window.scrollTo(x, y);
        }
      }
    }
  }, [errors]);

  const sumbitItem = () => {
    if (itemInfo.itemStatus == 4) {
      saveDeleteItem();
    } else {
      setBtnSave(true);
      setFlagAdd(!flagAdd);
      checkValidations(true);
    }
  };

  useEffect(() => {
    if (privateProfileInfo?.idCatLndUser != null && userCurrency != '') {
      if (areFieldsValidated) {
        saveEditItem();
      }
    } else if (!token && areFieldsValidated) saveEditItem();
  }, [privateProfileInfo, areFieldsValidated, userCurrency]);

  const saveEditItem = async () => {
    if (!token) {
      localStorage.setItem('redirectState', JSON.stringify({
        ...state,
        kFromDraft: true,
      }));

      history.push('/login');
    } else {
      setIsBlocked(true);

      document.body.classList.add('not-scroll');
      const rentalActive = {
        isHourlyRental,
        isDailyRental,
        isWeeklyRental,
        isMonthlyRental,
      };
      createUpdateItemInfo(
        itemInfo,
        itemImages,
        userTerms,
        itemVideos,
        deletedVideos,
        itemAccessories,
        deletedImages,
        blockedIndDates,
        rentalActive,
        userCurrency,
        currencySource,
        t,
      )
        .then((result: any) => {
          document.body.classList.remove('not-scroll');
          setIsBlocked(false);
          setBtnSave(false);
          setFlagAdd(!flagAdd);
          checkValidations(false);
          updateValidationStatus(false);
          if (result === 1) {
            resetItemInfo();
            updateItemInfo({
              ...itemInfo,
              hourlyRentalPrice: 0,
              dailyRentalPrice: 0,
              weeklyRentalPrice: 0,
              monthlyRentalPrice: 0,
              replacementCost: 0,
              salePrice: 0,
            });
            setIsReset(true);
            setOpenCongratulationsDialog(true);
          } else { localStorage.removeItem('isSavingItem'); }
          discardLocalStorage();
        }).catch(() => {
          setIsBlocked(false);
          document.body.classList.remove('not-scroll');
        });
    }
  };

  const saveDeleteItem = async () => {
    if (!token) {
      localStorage.setItem('redirectState', JSON.stringify({
        ...state,
        kFromDraft: true,
      }));

      history.push('/login');
    } else {
      deleteItem(itemInfo, t)
        .then((result: any) => {
          if (result === 1) {
            resetItemInfo();
            history.push('/listing');
          }
          discardLocalStorage();
        }).catch(() => {
          document.body.classList.remove('not-scroll');
        });
    }
  };

  const removePictureFromArray = (id: any, index: any) => {
    if (id != undefined) {
      deletedImages.push(itemImages[index]);
      updateDeletedImages(deletedImages);
      itemImages.splice(index, 1);
      updateItemImages(itemImages);
      saveImageDraft(itemImages);
    } else {
      itemImages.splice(index, 1);
      updateItemImages(itemImages);
      saveImageDraft(itemImages);
    }
  };

  const removeVideoFromArray = (id: any, index: any) => {
    if (id != undefined) {
      deletedVideos.push(itemVideos[index]);
      updateDeletedVideos(deletedVideos);
      itemVideos.splice(index, 1);
      updateItemVideos(itemVideos);
      saveVideoDraft(itemVideos);
    } else {
      itemVideos.splice(index, 1);
      updateItemVideos(itemVideos);
      saveVideoDraft(itemVideos);
    }
  };

  const onImageDropped = (e: any) => {
    const allowedImages = 6 - itemImages.length - e.length;
    if (allowedImages >= 0) {
      Array.from(e).forEach((file: any) => {
        // eslint-disable-next-line no-new
        new Compressor(file, {
          convertTypes: [
            'image/png',
            'image/webp',
            'image/bmp',
            'image/tiff',
          ],
          quality: 0.8,
          convertSize: 1000,
          maxWidth: 4096,
          maxHeight: 4096,
          minWidth: 1080,
          minHeight: 1920,
          success: (compressedResult) => {
            // compressedResult has the compressed file.
            // Use the compressed file to upload the images to your server.
            const reader = new FileReader();
            reader.onloadend = () => {
              itemImages.push(reader.result as string);
              updateItemImages(itemImages);
              saveImageDraft(itemImages);
            };
            reader.readAsDataURL(compressedResult);
          },
        });
      });
    } else {
      toast.error(t('add_item_page.6_pictures'));
    }
  };

  const onVideoDropped = (e: any) => {
    const allowedVideos = 2 - (itemVideos?.length ?? 0) - (e?.length ?? 0);
    if (allowedVideos >= 0) {
      Array.from(e).forEach((file: any) => {
        const sizeInMB = file.size / (1024 * 1024);
        if (sizeInMB > 512) {
          toast.error(t('add_item_page.video_size_alert'));
          return;
        }
        const reader = new FileReader();

        reader.onloadend = async () => {
          const base64String = reader.result as string;
          if (file.type != 'video/quicktime') {
            // Video is mp4
            const objNewVideo = {
              base64: base64String,
              name: `vid_${uuid()}_${file.name}`,
              file, // Almacenar el archivo completo aquí
              sizeInMB: sizeInMB.toFixed(2),
            };
            itemVideos?.push(objNewVideo);
            updateItemVideos(itemVideos, idCatLndItem);
            saveVideoDraft(itemVideos);
            if (parseInt(sizeInMB.toFixed(2), 10) > 5) setCanSaveDraftVideo(false);
          } else if (file.type == 'video/quicktime') {
            // video is mov

            if (sizeInMB < 150) {
              setIsBlocked(true);
              await convertVideoInBackend(file, sizeInMB, t);
              setIsBlocked(false);
              if (parseInt(sizeInMB.toFixed(2), 10) > 5) setCanSaveDraftVideo(false);
            } else {
              toast.warning(t('add_item_page.mov_video_size_alert'));
            }
          }
        };
        reader.readAsDataURL(file);
      });
    } else {
      toast.error(t('add_item_page.2_videos'));
    }
  };

  const swapVideo = (e: any) => {
    updateItemVideos(e);
    saveVideoDraft(e);
  };

  const swapImage = (e: any) => {
    updateItemImages(e);
    saveImageDraft(e);
  };

  const updateStatus = (e: any) => {
    // If status Inactive
    if (e.target.value == 0 || e.target.value == 4) {
      updateItemInfo({
        ...itemInfo,
        active: false,
        circlesOnly: false,
        exploreOnly: false,
        circlesAndExplore: false,
        itemStatus: e.target.value,
      });
      updateDraftItemInfo({
        ...itemInfo,
        active: false,
        circlesOnly: false,
        exploreOnly: false,
        circlesAndExplore: false,
        itemStatus: e.target.value,
      });
    }
    // if status everywhere
    if (e.target.value == 1) {
      updateItemInfo({
        ...itemInfo,
        circlesAndExplore: true,
        circlesOnly: false,
        exploreOnly: false,
        active: true,
        itemStatus: 1,
      });
      updateDraftItemInfo({
        ...itemInfo,
        circlesAndExplore: true,
        circlesOnly: false,
        exploreOnly: false,
        active: true,
        itemStatus: 1,
      });
    }
    // If status circle only
    if (e.target.value == 2) {
      updateItemInfo({
        ...itemInfo,
        circlesOnly: true,
        exploreOnly: false,
        circlesAndExplore: false,
        active: true,
        itemStatus: 2,
      });
      updateDraftItemInfo({
        ...itemInfo,
        circlesOnly: true,
        exploreOnly: false,
        circlesAndExplore: false,
        active: true,
        itemStatus: 2,
      });
    }

    // if status explore only
    if (e.target.value == 3) {
      updateItemInfo({
        ...itemInfo,
        exploreOnly: true,
        circlesOnly: false,
        circlesAndExplore: false,
        active: true,
        itemStatus: 3,
      });
      updateDraftItemInfo({
        ...itemInfo,
        exploreOnly: true,
        circlesOnly: false,
        circlesAndExplore: false,
        active: true,
        itemStatus: 3,
      });
    }
  };

  const goToListing = () => {
    window.scrollTo(0, 0);
    history.push('/listing', {
      ...state,
      kItemId: state.kItemId,
      kItemIndex: state.kItemIndex,
      updateSwiper: state.updateSwiper,
      kShowDetailsMobile: !state.kItemIsNew,
    });
  };

  const discardLocalStorage = () => {
    localStorage.removeItem('draftItemIdImage');
    localStorage.removeItem('draftItemIdVideo');
    localStorage.removeItem('draftItemInfo');
    localStorage.removeItem('draftAccessoriesInfo');
    localStorage.removeItem('draftRequirementList');
    localStorage.removeItem('draftBlockedIndDates');
  };

  const closeDialogModal = () => {
    discardLocalStorage();
    setOpen(false);
    goToListing();
  };

  const closeDialogWithoutSaveDraft = () => {
    setOpen(false);
    goToListing();
  };

  const handleDraftDialog = () => {
    if (state.kItemIsNew) {
      setOpen(true);
    } else {
      goToListing();
    }
  };

  let typeElement = '';
  switch (itemInfo?.idCatTypeItem) {
    case 1:
      typeElement = 'item';
      break;
    case 2:
      typeElement = 'service';
      break;
    case 3:
      typeElement = 'place';
      break;
    default:
      typeElement = 'item';
      break;
  }

  const { t } = useTranslation('global');

  // const [requiresIdVerify, setRequiresIdVerify] = useState(false);
  useEffect(() => {
    // setRequiresIdVerify(itemInfo?.requiresIdVerify);
    setMaxRate(itemInfo?.hasServiceMaxRate);
    setServiceMaxRateValue(itemInfo?.serviceMaxRate);
  }, []);

  /*
  const onChangeIdVerify = (e: any) => {
    const newValue = e.target.checked;
    if (!newValue) {
      setOpenRequireRenterId(true);
    } else {
      updateRequireVerify(newValue);
    }
  };
  */

  const onChangeMaxRate = (e: any) => {
    const newValue = e.target.checked;
    updateMaxRate(newValue);

    setMaxRate(newValue);
  };
  const onChangeServiceMaxRate = (e: any) => {
    const newValue = e.target.value;
    updateServiceMaxRate(newValue);

    setServiceMaxRateValue(newValue);
  };

  const updateRequireVerify = (value: any) => {
    setOpenRequireRenterId(false);
    // setRequiresIdVerify(value);
    updateItemInfo({
      ...itemInfo,
      requiresIdVerify: value,
    });
  };
  const updateMaxRate = (value: any) => {
    setMaxRate(value);
    updateItemInfo({
      ...itemInfo,
      hasServiceMaxRate: value,
    });
  };
  const updateServiceMaxRate = (value: any) => {
    setServiceMaxRateValue(value);
    updateItemInfo({
      ...itemInfo,
      serviceMaxRate: value,
    });
  };
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const titlePage = state.kItemIsNew ? `${t('add_item_page.add')} ${t(`add_item_page.${typeElement}`)}` : `${t('add_item_page.edit')} ${t(`add_item_page.${typeElement}`)}`;

  async function fetchAddNewItem() {
    cleanErrorsItem();

    if (Object.keys(privateProfileInfo).length === 0 || privateProfileInfo.length <= 3) {
      await getPrivateProfileInfo({ idCatLender: 0 });
    }
    _setCurrency();
    await getItemInfo(0);
  }

  return (
    <>
      <AppBarSecondary
        kBack
        kTitle={
          (
            <SectionTitle
              kMarginTop={0}
              kMarginBottom={0}
              kTitle={titlePage}
            />
          )
        }
      />
      <ContentLayout kPaddingXs={0}>
        <Grid container spacing={{ md: 3 }}>

          {/* Main Content */}
          <Grid item mb={3} xs={12} sm={12} md={8}>
            <Card elevation={0}>
              <Stack
                px={1}
                py={1}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={3}
                sx={{
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                <IconButton color="primary" onClick={() => handleDraftDialog()} sx={{ py: 1.4 }}>
                  <ArrowBackIosRounded />
                </IconButton>
                <HeaderTitle
                  kTitle={titlePage}
                />
              </Stack>
              <Divider sx={{
                display: { xs: 'none', md: 'flex' },
              }}
              />
              <CardContent sx={{
                position: 'relative',
              }}
              >
                {itemInfo == undefined
                  || itemAccessories == undefined
                  || userTerms == undefined
                  || blockedIndDates == undefined
                  || itemVideos == undefined
                  ? (
                    <Paper elevation={0} sx={{ height: { xs: 'calc(100vh - 40px)', lg: 'auto' } }}>
                      <LoadingLayout />
                    </Paper>
                  )
                  : (
                    <>
                      <Box
                        sx={{
                          position: 'fixed',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          zIndex: 9999,
                          backgroundColor: 'rgba(255, 255, 255, 0.7)',
                          display: isBlocked ? 'block' : 'none',
                        }}
                      >
                        <LoadingLayout />
                      </Box>
                      <ImagesGridList
                        isActivity={false}
                        itemImages={itemImages}
                        itemVideos={itemVideos}
                        removeVideoFromArray={removeVideoFromArray}
                        swapVideo={swapVideo}
                        removePictureFromArray={removePictureFromArray}
                        swapImage={swapImage}
                        onImageDropped={onImageDropped}
                        onVideoDropped={onVideoDropped}
                        isBlocked={isBlocked}
                        isSave={BtnSave}
                      />

                      {/* Basic Information */}
                      <Box mt={3}>
                        <Heading kTitle={t('add_item_page.basic_information')} />
                      </Box>
                      <Paper
                        variant="outlined"
                        sx={{
                          p: { xs: 0, sm: 2 },
                          borderWidth: { xs: 0, sm: 1 },
                          borderRadius: '10px',
                        }}
                      >
                        <ItemBasicInformation userCurrency={userCurrency} isBlocked={isBlocked} isReset={isReset} setIsReset={setIsReset} />
                      </Paper>

                      {/* ID Verification */}
                      {
                        /*
                      <IdVerificationSwitch
                        kItemInfo={itemInfo}
                        kRequiresIdVerify={requiresIdVerify}
                        kOnChangeIdVerify={onChangeIdVerify}
                      />
                      */
                      }
                      {/* Max Rate */}
                      {/* if tab is service */}
                      { parseInt(itemInfo?.idCatTypeItem, 10) == 2 ? (
                        <MaxRateSwitch
                          kItemInfo={itemInfo}
                          khasMaxRate={hasMaxRate}
                          kOnChangeMaxRate={onChangeMaxRate}
                          kserviceMaxRate={serviceMaxRateValue}
                          konChangeServiceMaxRate={onChangeServiceMaxRate}
                        />
                      ) : null}
                      {/* Delivery Options */}
                      {(itemInfo?.idCatTypeItem === 1 || itemInfo?.idCatTypeItem == '1') && (
                        <>
                          <Box mt={3}>
                            <Heading kTitle={t('item_detail.delivery_options')} />
                          </Box>
                          <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
                            <ItemDelivery userCurrency={userCurrency} />
                          </Paper>
                        </>
                      )}

                      {/* Accessories */}
                      <Box mt={3}>
                        <Heading kTitle={t('item_detail.accessories')} />
                      </Box>
                      <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
                        <ItemAccessories userCurrency={userCurrency} />
                      </Paper>

                      {/* Special Instructions */}
                      <Box mt={3}>
                        <Heading kTitle={t('item_detail.special_instructions')} />
                      </Box>
                      <Paper variant="outlined" sx={{ borderRadius: '10px' }}>
                        <ItemRequirements />
                      </Paper>

                      {/* Blocked Dates */}
                      <Box mt={3}>
                        <Heading kTitle={t('item_detail.blocked_dates')} />
                      </Box>
                      <Paper
                        variant="outlined"
                        sx={{ borderRadius: '10px' }}
                      >
                        <BlockedCalendar />
                      </Paper>

                      {/* Manage Circles */}
                      {!state.kItemIsNew ? <ManageCirclesSection /> : null}

                    </>
                  )}

              </CardContent>
            </Card>
          </Grid>

          {/* Aside Bar Content */}
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            sx={{
              zIndex: 1,
              pt: { md: 0, lg: 0 },
              display: {
                xs:
                  itemInfo == undefined
                    || itemAccessories == undefined
                    || userTerms == undefined
                    || blockedIndDates == undefined ? 'none' : 'initial',
                lg: 'initial',
              },
            }}
          >
            <Card elevation={0} sx={{ display: 'block', position: 'sticky', top: { md: '75px', xs: 'auto' } }}>
              {itemInfo?.idCatLndItem != 0 && (
                <Stack
                  px={1}
                  py={1}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={3}
                >
                  <HeaderTitle
                    kTitle={t('item_detail.status')}
                  />
                </Stack>
              )}
              {itemInfo?.idCatLndItem != 0 && <Divider />}
              <CardContent>
                <ListingStatus
                  itemInfo={itemInfo}
                  updateStatus={updateStatus}
                  kSaveItem={() => { sumbitItem(); }}
                />
              </CardContent>
            </Card>

          </Grid>
        </Grid>
      </ContentLayout>
      <DialogLists
        kTitle={t('checkout.congratulations')}
        kMaxWidth="sm"
        kFullScreen={false}
        kDisableContentPadding
        kDisableTitle
        kDisableActions
        kOpenDialogLists={openCongratulationsDialog}
        kOnClose={clickCloseCongratulationsDialog}
        kOnCancel={clickCloseCongratulationsDialog}
        kOnSave={clickCloseCongratulationsDialog}
        kContent={(
          privateProfileInfo.hasPayOutAccount ? (
            <DialogListingCreated
              handleDialog={(addNewItem: any) => {
                if (addNewItem) { fetchAddNewItem(); }
                setOpenCongratulationsDialog(false);
              }}
            />
          ) : <DialogSetupPayout handleDialog={clickCloseCongratulationsDialog} />
        )}
      />
      <DialogCommon
        kTitle={t('listing.save_item')}
        kMaxWidth="sm"
        kMaxHeight={isMobile ? '290px' : '240px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kBorderRadius={isMobile ? 0 : '10px'}
        kAlignBtn="center"
        kKeepBtnSize
        kTopDivider
        kSlideTransition={isMobile}
        kOpenDialogLists={open}
        kOnClose={closeDialogModal}
        kOnSecondary={closeDialogModal}
        kSecondaryBtnText={t('listing.discard_item_draft')}
        kOnPrimary={() => closeDialogWithoutSaveDraft()}
        kContent={
          (
            <>
              {canSaveDraftVideo
                ? <Typography>{t('listing.save_item_draft')}</Typography>
                : (
                  <Stack spacing={0.5}>
                    <Typography>{t('listing.save_item_draft')}</Typography>
                    <Typography>{t('listing.item_video_draft')}</Typography>
                  </Stack>
                )}
            </>
          )
        }
      />
      <DialogCommon
        // kTitle={t('item_detail.renter_id_verification')}
        kMaxWidth="sm"
        kMaxHeight="400px"
        kAlignBtn="center"
        kBorderRadius={isMobile ? 0 : '10px'}
        kFixedDialog={0}
        kFixedDialogPosition="fixed"
        kDisableBottomDivider
        kKeepBtnSize
        // kTopDivider
        kSlideTransition={isMobile}
        kOpenDialogLists={openRequireRenterId}
        kOnClose={() => { setOpenRequireRenterId(false); }}
        kOnSecondary={() => { setOpenRequireRenterId(false); }}
        kPrimaryBtnText={t('listing_detail.not_required')}
        kOnPrimary={() => {
          updateRequireVerify(false);
        }}
        kContent={
          (

            <Stack direction="column" justifyContent="flex-center" alignItems="center">
              <Avatar sx={{ width: 80, height: 80, backgroundColor: '#f2f2d6' }}>
                <WarningIcon fontSize="large" sx={{ color: '#e5be49' }} />
              </Avatar>
              <Box height={20} />
              <Typography mr={2} fontSize="h6.fontSize" fontWeight={700}>
                {t('listing_detail.require_id_verification')}
              </Typography>
              <Box height={20} />
              <Typography alignItems="center">
                {t('listing_detail.remember_verified')}
              </Typography>
            </Stack>
          )
        }
      />
    </>
  );
}
