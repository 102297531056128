/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import {
  Box, Typography, Paper, Stack, Button, useMediaQuery, useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import InfoIcon from '@mui/icons-material/ThumbUpOutlined';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import CreditScoreRoundedIcon from '@mui/icons-material/CreditScoreRounded';
import { useContext } from 'react';
import ActivityStatusStyle from './ActivityStatus.style';
import { ActivityStatusModel, ActivityTitleStatusModel, ActivityActionStatusModel } from './ActivityStatus.models';
import { SectionTitle } from '../../../../../../components/Common';
import activityActionsContext from '../../../../../../context/activity/actions/activityActionsContext';
// import activityContext from '../../../../../../context/activity/activityContext';

export default function ActivityStatus(props: ActivityStatusModel) {
  const { t } = useTranslation('global');
  const itemInfo = props.kItemInfo;
  let cardStatusText = '';
  let cardStatusColor = '';
  let cardTypeAction = '';
  let statusMessage = '';
  let showViewProgress = false;

  if (itemInfo.notifActionType == 'approve' || itemInfo.notifActionType == 'new_rental'
    || itemInfo.notifActionType == 'approved' || itemInfo.notifActionType == 'pickup'
    || itemInfo.notifActionType == 'dropoff' || itemInfo.notifActionType == 'review') {
    showViewProgress = true;
  }
  switch (itemInfo.notifActionType) {
    case 'new_rental':
    case 'new_buy':
    case 'approve':
    case 'approved':
      if (!itemInfo.isLenderApproved) {
        cardStatusColor = 'brandblue';
        cardTypeAction = itemInfo.notifType == 'RENT' ? '' : 'approve';
        cardStatusText = itemInfo.notifType == 'RENT'
          ? 'activity.status.pending_lender'
          : 'activity.status.pending_your';
      } else if (parseFloat(itemInfo.exchangeLat) > 0) {
        cardStatusColor = 'brandblue';
        cardStatusText = itemInfo.notifType == 'RENT'
          ? 'activity.status.approved_lender'
          : 'activity.status.approved_you';
      }
      break;
    case 'rejected':
      cardStatusColor = 'red';
      cardStatusText = itemInfo.notifType == 'RENT' ? 'activity.status.rejected_lender' : 'activity.status.rejected_you';
      cardTypeAction = 'rejected';
      statusMessage = itemInfo?.cancelReason != ''
        ? `${t('activity.reservation.rejection_reason')} ${itemInfo.cancelReason}`
        : `${t('activity.reservation.rejection_reason_no')}`;
      break;
    case 'pickup':
      cardTypeAction = 'pickup';
      cardStatusColor = 'pink';
      cardStatusText = 'activity.status.scheduled_pickup';
      break;
    case 'dropoff':
      cardTypeAction = 'dropoff';
      cardStatusColor = 'purple';
      cardStatusText = 'activity.status.scheduled_dropoff';
      break;
    case 'payment_processed':
      cardStatusColor = 'green';
      cardStatusText = itemInfo.notifType == 'RENT'
        ? 'activity.status.your_payment_processed'
        : 'activity.status.payment_processed';
      break;
    case 'review':
      cardStatusColor = itemInfo.isReviewed ? 'gray' : 'orange';
      cardTypeAction = itemInfo.isReviewed ? '' : 'review';
      cardStatusText = itemInfo.isReviewed ? 'activity.status.completed' : 'activity.status.review_experience';
      statusMessage = itemInfo.isReviewed ? '' : `${t('activity.review_modal.how_did_it_go')} ${t('activity.review_modal.comments')} ${itemInfo.notifType == 'RENT' ? itemInfo.lenderName : itemInfo.renterName}`;
      break;
    case 'cancelled':
      cardTypeAction = 'cancelled';
      const cancelComment = itemInfo.isCancelledRenter ? itemInfo?.cancelledRenterComments : itemInfo?.cancelledLenderCommnets;
      if (itemInfo.isCancelledRenter || itemInfo.isCancelledLender) {
        cardStatusColor = 'red';
        cardStatusText = itemInfo.notifType == 'RENT'
          ? itemInfo.isCancelledRenter
            ? 'activity.status.canceled_you'
            : 'activity.status.canceled_lender'
          : itemInfo.isCancelledLender
            ? 'activity.status.canceled_you'
            : 'activity.status.canceled_renter';
      } else {
        cardStatusColor = 'red';
        cardStatusText = 'activity.status.reservation_canceled';
      }

      if (itemInfo.notifType == 'RENT') {
        if (itemInfo?.cancelledRenterComments != '') {
          statusMessage = `${t('activity.reservation.cancelation_reason')} ${cancelComment}`;
        } else {
          statusMessage = `${t('activity.reservation.cancelation_reason')} ${itemInfo?.cancelledLenderCommnets}`;
        }
      } else if (itemInfo.notifType == 'LEND') {
        if (itemInfo?.cancelledLenderCommnets != '') {
          statusMessage = `${t('activity.reservation.cancelation_reason')} ${cancelComment}`;
        } else {
          statusMessage = `${t('activity.reservation.cancelation_reason')} ${itemInfo?.cancelledRenterComments}`;
        }
      }

      break;
    case 'approve_buy':
      if (!itemInfo.isLenderApproved) {
        cardStatusColor = 'brandblue';
        cardStatusText = itemInfo.notifType == 'RENT'
          ? 'activity.status.lender_approval'
          : 'activity.status.your_approval';
        cardTypeAction = 'approve_buy';
      }
      break;
    case 'verified_failed':
      cardStatusColor = 'red';
      cardStatusText = 'activity.status.id_verification_failed';
      break;
    case 'verified':
      cardStatusColor = 'green';
      cardStatusText = 'activity.status.id_verification_success';
      break;
    case 'verifypending':
      cardStatusColor = 'brandblue';
      cardStatusText = 'activity.status.id_being_verified';
      break;
    case 'verified_ok':
      cardStatusColor = 'green';
      cardStatusText = 'activity.status.id_verification_success';
      break;
    case 'joincirclerequest':
      if (itemInfo.notifType == 'CIRCLES') {
        cardStatusText = 'activity.status.join_circle';
      }
      break;
    case 'joincirclerequest_approved':
      if (itemInfo.notifType == 'CIRCLES') {
        cardStatusText = 'activity.status.join_approved';
      }
      break;
    case 'joincirclerequest_denied':
      if (itemInfo.notifType == 'CIRCLES') {
        cardStatusText = 'activity.status.join_denied';
      }
      break;
    case 'circle_promoted_owner':
      if (itemInfo.notifType == 'CIRCLES') {
        cardStatusText = 'activity.status.promoted_owner';
      }
      break;
    case 'circle_promoted_admin':
      if (itemInfo.notifType == 'CIRCLES') {
        cardStatusText = 'activity.status.promoted_admin';
      }
      break;
    case 'circle_member_blcked':
      if (itemInfo.notifType == 'CIRCLES') {
        cardStatusText = 'activity.status.blocked_circle';
      }
      break;
    case 'circle_promoted_user_admin':
      if (itemInfo.notifType == 'CIRCLES') {
        cardStatusText = 'activity.status.member_promoted_admin';
      }
      break;
    case 'circle_member_admin_blcked':
      if (itemInfo.notifType == 'CIRCLES') {
        cardStatusText = 'activity.status.member_blocked_circle';
      }
      break;
    case 'circle_downgrade_admin':
      if (itemInfo.notifType == 'CIRCLES') {
        cardStatusText = 'activity.status.no_longer_admin';
      }
      break;
    case 'circle_downgrade_owner':
      if (itemInfo.notifType == 'CIRCLES') {
        cardStatusText = 'activity.status.no_longer_owner';
      }
      break;
    case 'circle_item_blocked':
      if (itemInfo.notifType == 'CIRCLES') {
        cardStatusText = 'activity.status.item_blocked';
      }
      break;
    case 'circle_item_unblocked':
      if (itemInfo.notifType == 'CIRCLES') {
        cardStatusText = 'activity.status.item_unblocked';
      }
      break;
    case 'circle_owner_earning':
      if (itemInfo.notifType == 'CIRCLES') {
        cardStatusText = itemInfo.dscription;
      }
      break;
    case 'driver_assigned':
      cardStatusColor = 'green';
      cardStatusText = 'activity.status.driver_assigned';
      break;
    case 'en_route_to_pickup':
      cardStatusColor = 'green';
      cardStatusText = 'activity.status.driver_headed_pickup';
      break;
    case 'at_pickup':
      cardStatusColor = 'green';
      cardStatusText = 'activity.status.driver_at_pickup';
      break;
    case 'pickup_confirmed':
      cardStatusColor = 'green';
      cardStatusText = 'activity.status.driver_shipment';
      break;
    case 'en_route_to_delivery':
      cardStatusColor = 'green';
      cardStatusText = 'activity.status.driver_headed_delivery';
      break;
    case 'at_delivery':
      cardStatusColor = 'green';
      cardStatusText = 'activity.status.driver_at_delivery';
      break;
    case 'delivery_confirmed':
    case 'delivery_details_added':
      cardStatusColor = 'green';
      cardStatusText = 'activity.status.driver_delivered_shipment';
      break;
    case 'delivery_attempted':
      cardStatusColor = 'red';
      cardStatusText = 'activity.status.driver_not_able';
      break;
    case 'returned':
      cardStatusColor = 'orange';
      cardStatusText = 'activity.status.shipment_return';
      break;
    case 'canceled':
      cardStatusColor = 'red';
      cardStatusText = 'activity.status.shipment_canceled';
      break;
    case 'driver_approaching_pickup':
      cardStatusColor = 'orange';
      cardStatusText = 'activity.status.driver_near_pickup';
      break;
    case 'driver_approaching_delivery':
      cardStatusColor = 'orange';
      cardStatusText = 'activity.status.driver_near_delivery';
      break;
    case 'scheduled':
      cardStatusColor = 'orange';
      cardStatusText = 'activity.status.shipment_scheduled';
      break;
    case 'assigned':
      cardStatusColor = 'orange';
      cardStatusText = 'activity.status.driver_assigned';
      break;
    case 'en_route':
      cardStatusColor = 'green';
      cardStatusText = 'activity.status.item_pickup';
      break;
    case 'delivered':
      cardStatusColor = 'green';
      cardStatusText = 'activity.status.item_delivery_success';
      break;
    case 'attempted':
      cardStatusColor = 'red';
      cardStatusText = 'activity.status.shipment_unsuccess';
      break;
    case 'expired':
      cardStatusColor = 'orange';
      cardStatusText = 'activity.status.delivery_window';
      break;
    case 'reminder':
      cardTypeAction = 'reminder';
      cardStatusColor = 'orange';
      cardStatusText = 'activity_item.reminder';
      break;
    case 'extend_reservation_pending':
      cardTypeAction = (itemInfo?.idCatLndRentalStatus === 3 || itemInfo?.idCatLndRentalStatus === 5) ? 'extend' : '';
      cardStatusColor = 'brandblue';
      cardStatusText = 'activity_item.pendingExtendReservation';
      break;
    case 'extend_reservation_approved':
      cardTypeAction = (itemInfo?.idCatLndRentalStatus === 3 || itemInfo?.idCatLndRentalStatus === 5) ? 'extend' : '';
      cardStatusColor = 'brandblue';
      cardStatusText = 'activity_item.approvedExtendReservation';
      break;
    case 'extend_reservation_denied':
      cardTypeAction = (itemInfo?.idCatLndRentalStatus === 3 || itemInfo?.idCatLndRentalStatus === 5) ? 'extend' : '';
      cardStatusColor = 'red';
      cardStatusText = 'activity_item.deniedExtendReservation';
      break;
    case 'extend_reservation_canceled':
      cardTypeAction = (itemInfo?.idCatLndRentalStatus === 3 || itemInfo?.idCatLndRentalStatus === 5) ? 'extend' : '';
      cardStatusColor = 'red';
      cardStatusText = 'activity_item.canceledExtendReservation';
      break;
    default:
      cardStatusColor = 'green';
      cardStatusText = 'activity.status.unknown';
      break;
  }

  return (
    <Box sx={ActivityStatusStyle}>
      <SectionTitle
        kMarginTop={3}
        kTitle={t('activity.status.status')}
        kFontSize={18}
      />
      <Paper variant="outlined" elevation={0}>
        <CardTitle
          kColorStatus={cardStatusColor}
          kTextStatus={t(cardStatusText)}
          kActionProgress={() => props.kActivityProgress()}
          kShowViewProgress={showViewProgress}
        />
        <CardContent
          kDescStatus={statusMessage}
          kTypeAction={cardTypeAction}
          kNotifType={itemInfo.notifType}
          kItemName={(itemInfo.notifActionType == 'review' && !itemInfo.isReviewed) ? itemInfo.name : ''}
          kItem={itemInfo}
          kUserInfo={props.kUserInfo}
          kPickUpImages={props.kPickUpImages}
          kDropOffImages={props.kDropOffImages}
          kActionApprove={() => { props.kActivityApprove(); }}
          kActionReject={() => { props.kActivityReject(); }}
          kActionVerify={() => { props.kActivityVerify(); }}
          kActionReview={() => { props.kActivityReview(); }}
          kopenModalPictures={() => { props.kopenModalPictures(); }}
        />
      </Paper>
    </Box>

  );
}

function CardTitle(props: ActivityTitleStatusModel) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('global');
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={`lmiw-status-header lmiw-status-${props.kColorStatus}`}
      p={1}
    >
      <Stack
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
      >
        {props.kColorStatus == 'brandblue' ? <ErrorOutlineOutlinedIcon /> : <></>}
        {props.kColorStatus == 'orange' ? <InfoIcon /> : <></>}
        {props.kColorStatus == 'red' ? <HighlightOffOutlinedIcon /> : <></>}
        {props.kColorStatus == 'green' ? <CheckCircleOutlineOutlinedIcon /> : <></>}
        {props.kColorStatus == 'gray' ? <CheckCircleOutlineOutlinedIcon /> : <></>}
        {props.kColorStatus == 'pink' ? <AccessTimeRoundedIcon /> : <></>}
        {props.kColorStatus == 'purple' ? <AccessTimeRoundedIcon /> : <></>}
        {props.kColorStatus == 'blue' ? <CreditScoreRoundedIcon /> : <></>}

        <Typography className="lmiw-status-title">
          {props.kTextStatus}
        </Typography>
      </Stack>
      {props.kShowViewProgress && (isMobile ? <ArrowForwardIosOutlinedIcon onClick={() => props.kActionProgress()} />
        : <Typography className="lmiw-status-btn-progress" onClick={() => props.kActionProgress()}>{t('activity.status.view_progress')}</Typography>)}

    </Stack>
  );
}

function CardContent(props: ActivityActionStatusModel) {
  const activityActionContext = useContext(activityActionsContext);
  // const activity = useContext(activityContext);
  // const {
  // userOwnInfo,
  // renterLenderInfo,
  // requiresRenterIdVerification,
  // }: any = activity;

  // const requireIDVerificationDisabled = (renterLenderInfo?.renterVerified! && userOwnInfo?.lenderVerified!)
  //  && ((renterLenderInfo?.renterVerifiedShufti! && !renterLenderInfo?.renterVerifiedStripe!)
  //    || (userOwnInfo?.lenderVerifiedShufti! && !userOwnInfo?.lenderVerifiedStripe!));

  const {
    disableButton,
  }: any = activityActionContext;

  const { t } = useTranslation('global');

  const isApproveDisabled = false; // requiresRenterIdVerification && !renterLenderInfo?.renterVerified;
  return (
    <>
      {props.kTypeAction ? (
        <Stack
          direction={{ sm: 'row', xs: 'column' }}
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          p={2}
        >
          {props.kDescStatus ? (
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              <Typography
                sx={{ margin: 0 }}
                style={{ wordWrap: 'break-word', overflowWrap: 'anywhere' }}
              >
                {props.kDescStatus}
              </Typography>
              {props.kItemName ? (
                <Typography sx={{ margin: 0 }} fontWeight="bold">
                  {props.kItemName}
                </Typography>
              ) : <></>}
            </Stack>
          ) : <></>}
          {((props.kNotifType == 'LEND' || props.kNotifType == 'RENT') && props.kTypeAction != '') ? (
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              {(props.kTypeAction === 'approve' || props.kTypeAction === 'approve_buy')
                ? (
                  <>
                    { /*
                        {!requireIDVerificationDisabled && requiresRenterIdVerification
                    ? (
                      <div style={{ display: 'block' }}>
                        <Typography className="lmiw-reservation-desc" sx={{ display: { xs: 'none', sm: 'block' } }}>{t('activity_detail.approved_text')}</Typography>
                        <Button variant="outlined" onClick={() => props.kActionReject()} sx={{ marginRight: 2 }}>{t('activity.status.reject')}</Button>
                        <Button variant="contained" disabled={isApproveDisabled} onClick={() => props.kActionApprove()}>{t('activity.status.approve')}</Button>
                      </div>
                    )
                    : */}
                    <>
                      <Button variant="outlined" onClick={() => props.kActionReject()}>{t('activity.status.reject')}</Button>
                      <Button variant="contained" disabled={isApproveDisabled} onClick={() => props.kActionApprove()}>{t('activity.status.approve')}</Button>
                    </>
                  </>
                )
                : <></>}
              {props.kTypeAction === 'verify'
                ? <Button variant="contained" onClick={() => props.kActionVerify()}>{t('activity.status.verify')}</Button>
                : <></>}
              {props.kTypeAction === 'review'
                ? <Button variant="contained" disabled={disableButton} onClick={() => props.kActionReview()}>{t('activity.status.review')}</Button>
                : <></>}
              {((props.kItem?.notifActionType === 'pickup' || props.kItem?.idCatLndRentalStatus === 3) && props.kPickUpImages?.length == 0
                ? (
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography>{t('activity_detail.upload_pictures_before_rental')}</Typography>
                    <Button variant="outlined" onClick={() => props.kopenModalPictures()}>{t('activity_detail.upload')}</Button>
                  </Stack>
                )
                : <></>
              )}
              {((props.kItem?.notifActionType === 'dropoff' || props.kItem?.idCatLndRentalStatus === 5) && props.kDropOffImages?.length == 0
                ? (
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography>{t('activity_detail.upload_pictures_after_rental')}</Typography>
                    <Button variant="outlined" onClick={() => props.kopenModalPictures()}>{t('activity_detail.upload')}</Button>
                  </Stack>
                )
                : <></>
              )}
            </Stack>
          )
            : (
              <Typography>
                {t('activity.status.required_id')}
              </Typography>
            )}
        </Stack>
      ) : <></>}
    </>
  );
}
