import {
  // Alert,
  // Box,
  CardContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';

import {
  SectionTitle, SectionDescription, SwiperThumbnails,
} from '../../../../../components/Common';
// import { ItemDetailModels } from './ItemDetaiModels';
import MyListingContext from '../../../../../context/myListing/myListingContext';
import LoadingLayout from '../../../../../components/Common/LoadingLayout/LoadingLayout';
import ItemDetailPricing from './ItemDetailPricing';
import ItemDetailLocation from './ItemDetailLocation';
import ItemDetailName from './ItemDetailName';
import ItemDetailAccesories from './ItemDetailAccesories';
import ItemDetailEspecialInstructions from './ItemDetailEspecialInstructions';
import ItemDetailCalendar from './ItemDetailCalendar';
import ItemDetailDelivery from './ItemDetailDelivery';
// import CategoryClassification from '../../../../../components/Common/CategoryClassification/CategoryClassification';

export default function ItemDetail() {
  const {
    images,
    condictions,
    accesories,
    loading,
    item,
    itemBlockedIndDates,
  }: any = useContext(MyListingContext);

  const { t } = useTranslation('global');

  const [listStatus, setListStatus] = useState('');
  const [titleByCat, setTitleByCat] = useState<string>(t('item_detail.item_location'));

  const defaulImage: any = [];

  useEffect(() => {
    if (item) {
      if (item.blocked && !item.sold) { setListStatus(t('item_detail.blocked')); } else {
        if (item.circlesAndExplore) { setListStatus(t('item_detail.list_everywere')); }
        if (item.exploreOnly) { setListStatus(t('item_detail.list_only_explore')); }
        if (item.circlesOnly) { setListStatus(t('item_detail.list_only_circle')); }
        if (item.sold) { setListStatus(t('item_detail.sold')); }
        if (!item.circlesAndExplore && !item.exploreOnly && !item.circlesOnly && !item.blocked) {
          setListStatus(t('item_detail.unavailable'));
        }
      }
    }
  }, [item]);

  useEffect(() => {
    switch (item?.idCatTypeItem) {
      case 1:
        setTitleByCat(t('item_detail.item_location'));
        break;
      case 2:
        setTitleByCat(t('item_detail.service_location'));
        break;
      default:
        setTitleByCat(t('item_detail.place_location'));
        break;
    }
  }, [item?.idCatTypeItem]);

  function formatDeliveryPrice(value: any) {
    const priceArray: any = value.split('.');
    if (parseInt(priceArray[1]) == 0) {
      return `$${priceArray[0]}`;
    }
    return `$${value}`;
  }

  return (
    <>
      {!loading ? (
        <CardContent>
          <SectionTitle
            kTitle={t('item_detail.photos_videos')}
            kFontSize={18}
            kMarginTop={0}
            kMarginBottom={1}
          />
          {
          /* item?.requiresIdVerify
            && (
            <Box sx={{ mb: 1 }}>
              <Alert severity="info">{t('listing_detail.requires_id_verification')}</Alert>
            </Box>
            ) */ }
          <SwiperThumbnails
            itemImages={!images ? defaulImage : images}
            height={460}
            thumbnailHeight={80}
          />
          <ItemDetailName
            kMarginTop={4}
            kMarginBottom={1}
            kNameItem={(item && item.name) ? item.name : `${'item_detail.no_title'}`}
            kListingStatus={listStatus}
            kForSale={(item && item.salePrice > 0)}
            kTotalViews={item && item.totalViews ? item.totalViews : 0}
          />
          <SectionDescription
            kTitleSize={18}
            kMarginTop={4}
            kMarginBottom={1}
            kTitle={t('item_detail.description')}
            kDescription={item ? item.dscription : '...'}
          />
          {/* <CategoryClassification itemName={item?.name} itemDescription={item?.dscription} /> */}
          <ItemDetailPricing
            kFontSize={18}
            kMarginTop={4}
            kMarginBottom={1}
            kValue={item && item.replacementCost && item.replacementCost !== '0.00' ? `$${parseInt(item.replacementCost, 10)}` : '--'}
            kPriceForSale={item && item.salePrice && item.salePrice !== '0.00' ? `$${parseInt(item.salePrice, 10)}` : '--'}
            kHourlyPrice={item && item.hourlyRentalPrice && item.hourlyRentalPrice !== '0.00' ? `$${parseInt(item.hourlyRentalPrice, 10)}` : '--'}
            kDailyPrice={item && item.dailyRentalPrice && item.dailyRentalPrice !== '0.00' ? `$${parseInt(item.dailyRentalPrice, 10)}` : '--'}
            kWeeklyPrice={item && item.weeklyRentalPrice && item.weeklyRentalPrice !== '0.00' ? `$${parseInt(item.weeklyRentalPrice, 10)}` : '--'}
            kMonthlyPrice={item && item.monthlyRentalPrice && item.monthlyRentalPrice !== '0.00' ? `$${parseInt(item.monthlyRentalPrice, 10)}` : '--'}
          />
          <SectionTitle
            kTitle={titleByCat}
            kFontSize={18}
            kMarginTop={4}
            kMarginBottom={1}
          />
          <ItemDetailLocation
            kLong={item?.lon}
            kLat={item?.lat}
            kExactAddress={item?.exactAddress}
            kAdress={item?.address}
          />

          <ItemDetailAccesories
            kTitle={t('item_detail.accessories')}
            kFontSize={18}
            kMarginTop={4}
            kMarginBottom={1}
            kAccesories={item && accesories ? accesories : []}
          />
          {item?.idCatTypeItem === '1' ? (
            <>
              <SectionTitle
                kTitle={t('item_detail.delivery_options')}
                kFontSize={18}
                kMarginTop={4}
                kMarginBottom={1}
              />
              <ItemDetailDelivery
                KActiveDelivery={(item?.isLenderDelivery)}
                kDelivery5Mile={item?.fiveMilesPrice ? formatDeliveryPrice(item.fiveMilesPrice) : '--'}
                kDelivery10Mile={item?.tenMilesPrice ? formatDeliveryPrice(item.tenMilesPrice) : '--'}
                kDelivery30Mile={item?.tFiveMilesPrice ? formatDeliveryPrice(item.tFiveMilesPrice) : '--'}
                kDelivery50Mile={item?.fiftyMilesPrice ? formatDeliveryPrice(item.fiftyMilesPrice) : '--'}
                KActiveRoadie={item?.roadie}
                kRoadieLength={item?.length ? `${item.length} ${t('item_detail.in')}` : '--'}
                kRoadieWidth={item?.width ? `${item.width} ${t('item_detail.in')}` : '--'}
                kRoadieHeight={item?.height ? `${item.height} ${t('item_detail.in')}` : '--'}
                kRoadieWeight={item?.weight ? `${item.weight} ${t('item_detail.pounds_lowercase')}` : '--'}
                kRoadieStartTime={item?.startDeliveryTime ? `${item.startDeliveryTime.split(' ').pop()}` : '--'}
                kRoadieEndTime={item?.endDeliveryTime ? `${item.endDeliveryTime.split(' ').pop()}` : '--'}
              />
            </>
          ) : null}

          <SectionTitle
            kTitle={t('item_detail.special_instructions')}
            kFontSize={18}
            kMarginTop={4}
            kMarginBottom={1}
          />
          <ItemDetailEspecialInstructions
            kEspecialInstructions={item && condictions ? condictions : []}
          />
          <SectionTitle
            kTitle={t('item_detail.blocked_dates')}
            kFontSize={18}
            kMarginTop={4}
            kMarginBottom={1}
          />
          <ItemDetailCalendar
            kBlockedDays={item && itemBlockedIndDates ? itemBlockedIndDates : []}
          />
        </CardContent>
      ) : <LoadingLayout />}

    </>

  );
}

ItemDetail.defaultProps = {
  kType: 'item',
};
