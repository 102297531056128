import { useContext, useEffect, useState } from 'react';
import {
  Typography, Table, TableBody, TableCell, TableContainer, TableRow, Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import IPData from 'ipdata';
import { LmiTheme } from '../../../../../components/Common';
import { ItemDetailDeliveryModels } from './ItemDetaiModels';
import { ReactComponent as DeliveryIcon } from '../../../../../assets/icons/lmi_delivery_icon.svg';
// import { ReactComponent as RoadieIcon } from '../../../../../assets/icons/lmi_roadie_icon.svg';
import publicProfileContext from '../../../../../context/publicProfile/publicProfileContext';
// import publicProfileContext from '../../../../../context/publicProfile/publicProfileContext';

function getIpData() {
  const [countryCode, setCountryCode] = useState('');
  useEffect(() => {
    const countryCodeSession = sessionStorage.getItem('country_code');
    setCountryCode(countryCodeSession || '');
    if (!countryCodeSession || countryCodeSession == '') {
      const ipdata = new IPData(`${process.env.REACT_APP_IPDATA}`);
      ipdata.lookup().then((data) => {
        setCountryCode(data.country_code);
        sessionStorage.setItem('country_code', data.country_code);
      });
    }
  }, []);

  return countryCode;
}

export default function ItemDetailDelivery(props: ItemDetailDeliveryModels) {
  const pProfileContext = useContext(publicProfileContext);
  const {
    privateProfileInfo,
  }: any = pProfileContext;

  // const [roadieVisibleFromPhoneCode, setRoadieVisibleFromPhoneCode] = useState(true);
  const countryCode = getIpData();
  const { t } = useTranslation('global');

  useEffect(() => {
  // Phone Code validation
    const renterPhoneNumb = privateProfileInfo?.phoneNumber;
    if (renterPhoneNumb?.length > 0) {
    // const phoneCode = renterPhoneNumb.slice(0, -10);
    /*
      if (phoneCode != '+1') {
        setRoadieVisibleFromPhoneCode(false);
      }
      */
    }
  }, [privateProfileInfo?.phoneNumber]);
  return (
    <>
      <Stack
        direction={{ md: 'row', xs: 'column' }}
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
        mb={1}
      >
        <TableContainer sx={{
          borderRadius: 2,
          border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
        }}
        >
          <Stack direction="row" justifyContent="flex-start" alignItems="center" p={2}>
            <DeliveryIcon width={24} height={24} style={{ marginRight: 16 }} />
            <Typography fontSize={16} fontWeight={700} color={LmiTheme.light.systemBackground.primary}>{t('item_detail.offer_delivery')}</Typography>
          </Stack>
          {props.KActiveDelivery
            ? (
              <Table>
                <TableBody sx={{ '& td, & th': { border: 0, py: 1 } }}>
                  <TableRow>
                    <TableCell>
                      <Typography component="span" fontWeight="bold" fontSize={14}>
                        {countryCode === 'US' ? `${t('pick_up.5_miles')}` : `${t('pick_up.8_km')}:`}
                      </Typography>
                      <Typography
                        color={LmiTheme.light.systemBackground.tertiary}
                        component="span"
                        fontWeight="500"
                        fontSize={14}
                        pl={1}
                      >
                        {props.kDelivery5Mile}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component="span" fontWeight="bold" fontSize={14}>
                        {countryCode === 'US' ? `${t('pick_up.10_miles')}` : `${t('pick_up.24_km')}`}
                        :
                      </Typography>
                      <Typography
                        color={LmiTheme.light.systemBackground.tertiary}
                        component="span"
                        fontWeight="500"
                        fontSize={14}
                        pl={1}
                      >
                        {props.kDelivery10Mile}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography component="span" fontWeight="bold" fontSize={14}>
                        {countryCode === 'US' ? `${t('pick_up.30_miles')}` : `${t('pick_up.48_km')}`}
                        :
                      </Typography>
                      <Typography
                        color={LmiTheme.light.systemBackground.tertiary}
                        component="span"
                        fontWeight="500"
                        fontSize={14}
                        pl={1}
                      >
                        {props.kDelivery30Mile}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component="span" fontWeight="bold" fontSize={14}>
                        {countryCode === 'US' ? `${t('pick_up.50_miles')}` : `${t('pick_up.80_km')}`}
                        :
                      </Typography>
                      <Typography
                        color={LmiTheme.light.systemBackground.tertiary}
                        component="span"
                        fontWeight="500"
                        fontSize={14}
                        pl={1}
                      >
                        {props.kDelivery50Mile}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )
            : (
              <Table>
                <TableBody sx={{ '& td': { border: 0, py: 1 } }}>
                  <TableRow>
                    <TableCell>
                      <Typography
                        color={LmiTheme.light.systemBackground.tertiary}
                        component="span"
                        fontWeight="500"
                        fontSize={14}
                        pl={1}
                      >
                        {t('item_detail.dont_offer_delivery')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}

        </TableContainer>
        {
        /*
        roadieVisibleFromPhoneCode && (
        <TableContainer sx={{
          borderRadius: 2,
          border: `1px solid ${LmiTheme.light.systemBackground.separator}`,
        }}
        >
          <Stack direction="row" justifyContent="flex-start" alignItems="center" p={2}>
            <RoadieIcon width={24} height={24} style={{ marginRight: 16 }} />
            <Typography fontSize={16} fontWeight={700} color={LmiTheme.light.systemBackground.primary}>{t('item_detail.third_party_service')}</Typography>
          </Stack>

          {props.KActiveRoadie
            ? (
              <Table>
                <TableBody sx={{ '& td': { border: 0, py: 1 } }}>
                  <TableRow>
                    <TableCell>
                      <Typography component="span" fontWeight="bold" fontSize={14}>{t('item_detail.length')}</Typography>
                      <Typography
                        color={LmiTheme.light.systemBackground.tertiary}
                        component="span"
                        fontWeight="500"
                        fontSize={14}
                        pl={1}
                      >
                        {props.kRoadieLength}
                      </Typography>

                    </TableCell>
                    <TableCell>
                      <Typography component="span" fontWeight="bold" fontSize={14}>{t('item_detail.width')}</Typography>
                      <Typography
                        color={LmiTheme.light.systemBackground.tertiary}
                        component="span"
                        fontWeight="500"
                        fontSize={14}
                        pl={1}
                      >
                        {props.kRoadieWidth}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography component="span" fontWeight="bold" fontSize={14}>{t('item_detail.height')}</Typography>
                      <Typography
                        color={LmiTheme.light.systemBackground.tertiary}
                        component="span"
                        fontWeight="500"
                        fontSize={14}
                        pl={1}
                      >
                        {props.kRoadieHeight}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component="span" fontWeight="bold" fontSize={14}>{t('item_detail.weight')}</Typography>
                      <Typography
                        color={LmiTheme.light.systemBackground.tertiary}
                        component="span"
                        fontWeight="500"
                        fontSize={14}
                        pl={1}
                      >
                        {props.kRoadieWeight}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography component="span" fontWeight="bold" fontSize={14}>{t('item_detail.start_time')}</Typography>
                      <Typography
                        color={LmiTheme.light.systemBackground.tertiary}
                        component="span"
                        fontWeight="500"
                        fontSize={14}
                        pl={1}
                      >
                        {props.kRoadieStartTime}

                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography component="span" fontWeight="bold" fontSize={14}>
                        {t('item_detail.end_time')}
                      </Typography>
                      <Typography
                        color={LmiTheme.light.systemBackground.tertiary}
                        component="span"
                        fontWeight="500"
                        fontSize={14}
                        pl={1}
                      >
                        {props.kRoadieEndTime}

                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )
            : (
              <Table>
                <TableBody sx={{ '& td': { border: 0, py: 1 } }}>
                  <TableRow>
                    <TableCell>
                      <Typography
                        color={LmiTheme.light.systemBackground.tertiary}
                        component="span"
                        fontWeight="500"
                        fontSize={14}
                        pl={1}
                      >
                        {t('item_detail.dont_offer_third_party')}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            )}
        </TableContainer>
        )
        */}
      </Stack>

    </>
  );
}
