/* eslint-disable no-nested-ternary */
import { useContext, useEffect, useState } from 'react';
import {
  Paper,
  Typography,
  Grid,
  Stack,
  Divider,
  Button,
  // Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
// import { PRECING_HOURLY } from '../../../types';
import { useHistory } from 'react-router-dom';
import LmiTheme from '../../../themes/lmi-mui-theme/lmi-theme';
import { SummaryModels } from './Summary.models';
import bookItemContext from '../../../context/bookItem/bookContext';
// import { SummaryModel } from '../../../context/authentication/models/summary-Model';
import { currentDate, formatDateLocal, formatter } from '../../../services/common';
import { PICKUPBYLENDER, RENT, ROADIE } from '../../../types';

function Summary(props: SummaryModels) {
  const bookContext = useContext(bookItemContext);
  const {
    bookDetails,
    summary,
    availability: {
      dateRange,
      errorAvailability,
    },
    precingType,
    deliveryType,
    accesories,
    bookType,
    validateBookNow,
  }: any = bookContext;

  // const {
  //   roadie,
  //   delivery:  Delivery,
  // } = bookDetails;
  const { feeLMI } = summary ?? { feeLMI: null };

  const { stripeFee } = feeLMI ?? { stripeFee: 0.0 };

  const [bookSummary, setBookSummary] = useState(summary);
  const [rental, setRental] = useState(bookSummary?.totalRental && bookSummary?.totalRental != 0 ? bookSummary?.totalRental : 0);
  const [totalAccesories, setTotalAccesories] = useState(bookSummary?.totalAccesories && bookSummary?.totalAccesories != 0 ? bookSummary?.totalAccesories : 0);
  const [rentalDiscount, setRentalDiscount] = useState(bookSummary?.grandTotal && bookSummary?.grandTotal != 0 ? bookSummary?.grandTotal : 0);
  const [grandTotal, setGrandTotla] = useState(bookSummary?.totalAfterDiscounts && bookSummary?.totalAfterDiscounts != 0 ? bookSummary?.totalAfterDiscounts : 0);
  const [periodToShow, setPeriodToShow] = useState('');

  useEffect(() => {
    setBookSummary(summary);
    setRental(summary?.totalRental && summary?.totalRental != 0 ? summary?.totalRental : 0);
    setTotalAccesories(summary?.totalAccesories && summary?.totalAccesories != 0 ? summary?.totalAccesories : 0);
    setGrandTotla(summary?.totalAfterDiscounts && summary?.totalAfterDiscounts != 0 ? summary?.totalAfterDiscounts : 0);
    setRentalDiscount(summary?.discountAmount && summary?.discountAmount != 0 ? summary?.discountAmount : 0);

    let rentalUnit = '';
    let rentalAction = t('listing_detail.rental');
    if (summary?.totalTiempo && bookType != 'BUY') {
      if (!dateRange[0]) {
        rentalUnit = '';
      } else if (summary.rentalType == 'HOURLY') {
        rentalUnit = summary?.totalTiempo > 1 ? `${t('activity.summary.hours')}` : `${t('activity.summary.hour')}`;
      } else if (summary.rentalType == 'DAILY') {
        rentalUnit = summary?.totalTiempo > 1 ? `${t('activity.summary.days')}` : `${t('activity.summary.day')}`;
      } else if (summary.rentalType == 'WEEKLY') {
        rentalUnit = summary?.totalTiempo > 1 ? `${t('activity.summary.weeks')}` : `${t('activity.summary.week')}`;
      } else if (summary.rentalType == 'MONTHLY') {
        rentalUnit = summary?.totalTiempo > 1 ? `${t('activity.summary.months')}` : `${t('activity.summary.month')}`;
      }
      setPeriodToShow(`${rentalAction} (${summary.totalTiempo} ${rentalUnit})`);
    } else if (bookType == 'BUY') {
      rentalUnit = '';
      rentalAction = t('listing_detail.purchase');
      setPeriodToShow(`${rentalAction}`);
    } else {
      setPeriodToShow('');
    }
  }, [summary]);

  // const currency = bookSummary?.currency != '' ? bookSummary?.currency : '';

  const hasErrorRange = ((precingType !== 'HOURLY') && errorAvailability && errorAvailability != '') || !dateRange[1];

  const startString = hasErrorRange
    ? '--'
    : formatDateLocal(dateRange[0] ?? currentDate);

  const endString = hasErrorRange
    ? '--'
    : formatDateLocal(dateRange[1] ?? dateRange[0] ?? currentDate);

  const [delivery, setDelivery] = useState(summary && summary?.self && summary?.self.fee > 0 ? summary?.self.fee : 0);

  useEffect(() => {
    switch (deliveryType) {
      case PICKUPBYLENDER:
        setDelivery(summary && summary?.self && summary?.self.fee > 0 ? summary?.self.fee : 0);
        break;
      case ROADIE:
        setDelivery(summary && summary.roadie && summary?.roadie.price > 0 ? summary?.roadie?.price : 0);
        break;
      default:

        break;
    }
  }, [deliveryType, summary]);

  const history = useHistory();

  const continueBook = async () => {
    const { redirect } = await validateBookNow();
    // const stateRedirect = toState;
    if (redirect && redirect != '') {
      history.push(redirect, {
        accesories,
        bookDetails,
        deliveryType,
        dateRange,
      });
    }
  };

  const goToTerms = async () => {
    history.push('/terms');
  };

  const { t } = useTranslation('global');

  function totalDeliveryCollectReturnFee(value: any, stpFee: any) {
    const totalWithStripe = (value / (1 - stpFee!));
    return formatter.format(parseInt(totalWithStripe.toFixed(0), 10));
  }

  return (
    <>
      <Paper
        onClick={props.kOnClickOpenDialogAvailability}
        variant="outlined"
        sx={{ borderRadius: '10px' }}
      >
        {bookType == RENT
          ? (
            <>
              <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid
                  item
                  xs={5}
                  py={1}
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: LmiTheme.light.systemBackground.tertiary,
                    }}
                  >
                    {t('listing_detail.start_date')}
                  </Typography>
                  <Typography
                    component="div"
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      color: LmiTheme.light.accent,
                      fontSize: 'body2.fontSize',
                      textTransform: 'capitalize',
                    }}
                  >
                    {startString}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs="auto"
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: LmiTheme.light.systemBackground.tertiary,
                      fontWeight: 'bold',
                    }}
                  >
                    {t('listing_detail.to')}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={5}
                  py={1}
                  textAlign="center"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: LmiTheme.light.systemBackground.tertiary,
                    }}
                  >
                    {t('listing_detail.end_date')}
                  </Typography>
                  <Typography
                    component="div"
                    sx={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      color: LmiTheme.light.accent,
                      fontSize: 'body2.fontSize',
                      textTransform: 'capitalize',
                    }}
                  >
                    {endString}
                  </Typography>
                </Grid>
              </Stack>
            </>
          )
          : null}
      </Paper>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        mt={3}
        mb={2}
      >
        <Typography sx={{ fontSize: 'body2.fontSize', fontWeight: 'bold' }}>{periodToShow}</Typography>
        {/* <Typography sx={{ fontSize: 'body2.fontSize', fontWeight: 'bold' }}>{t('listing_detail.rental')}</Typography> */}
        <Typography sx={{ fontSize: 'body2.fontSize', fontWeight: 'bold' }}>
          {formatter.format(rental)}
          {' '}
          {bookDetails?.currency}
        </Typography>
      </Stack>

      {accesories && accesories.length > 0 && totalAccesories > 0
        ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            my={2}
          >
            <Typography sx={{ fontSize: 'body2.fontSize', fontWeight: 'bold' }}>{t('listing_detail.accessories')}</Typography>
            <Typography sx={{ fontSize: 'body2.fontSize', fontWeight: 'bold' }}>
              {formatter.format(totalAccesories)}
              {' '}
              {bookDetails?.currency}
            </Typography>
          </Stack>
        ) : null}
      {rentalDiscount && rentalDiscount != 0
        ? (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            my={2}
          >
            <Typography sx={{ fontSize: 'body2.fontSize', fontWeight: 'bold', color: 'red' }}>
              {t('listing_detail.discount')}
              {` (${summary?.couponPercentage} % OFF)`}
            </Typography>
            <Typography sx={{ fontSize: 'body2.fontSize', fontWeight: 'bold', color: 'red' }}>
              {formatter.format(rentalDiscount)}
              {' '}
              {bookDetails?.currency}
            </Typography>
          </Stack>
        ) : null}

      {bookType != RENT || (deliveryType == 'NONE') || delivery <= 0 ? null : (
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          my={2}
        >
          <Typography sx={{ fontSize: 'body2.fontSize', fontWeight: 'bold' }}>{t('listing_detail.delivery_pickup')}</Typography>
          <Typography sx={{ fontSize: 'body2.fontSize', fontWeight: 'bold' }}>
            {(summary?.self) && formatter.format(parseInt(delivery?.toFixed(0), 10))}
            {(summary?.roadie) && totalDeliveryCollectReturnFee(delivery, stripeFee)}
            {' '}
            {bookDetails?.currency}
          </Typography>
        </Stack>
      )}

      <Divider />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        my={2}
      >
        <Typography sx={{ fontSize: 'subtitle1.fontSize', fontWeight: 'bold' }}>{t('listing_detail.total_cost')}</Typography>
        <Typography sx={{ fontSize: 'subtitle1.fontSize', fontWeight: 'bold' }}>
          {formatter.format(grandTotal)}
          {' '}
          {bookDetails?.currency}
        </Typography>
      </Stack>

      {/* The field isOwner is confusing , it is actually a field called 'canRentOwnItem' form sp
      , so, if it's false, the user shouldn't be able to rent this item */}
      {bookDetails?.isOwnItem ? (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          my={3}
        >
          <Button
            onClick={props.kIsBook ? () => continueBook() : props.kOnClickOpenDialogCongratulations}
            fullWidth
            variant="contained"
            size="large"
            disableElevation
            sx={{
              textTransform: 'none',
              fontSize: 'subtitle1.fontSize',
              fontWeight: 'bold',
              borderRadius: '10px',
            }}
          >
            {props.kIsBook ? (bookType == 'BUY') ? t('listing_detail.buy_now') : t('listing_detail.book_now') : t('listing_detail.pay_now')}
          </Button>
        </Stack>
      ) : null}
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        mb={2}
      >
        <Typography sx={{
          fontSize: 'body2.fontSize',
          color: LmiTheme.light.systemBackground.secondary,
        }}
        >
          {/* bookDetails?.requiresRenterIdVerification ? t('listing_detail.requires_id_verification') : t('listing_detail.no_requires_id_verification') */}
        </Typography>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        my={1}
      >
        <Typography
          sx={{
            fontSize: 'body2.fontSize',
            color: LmiTheme.light.systemBackground.secondary,
          }}
        >
          {bookType == RENT ? t('listing_detail.book_txt') : t('listing_detail.by_buy_txt')}
          <Button variant="text" onClick={goToTerms} sx={{ textTransform: 'capitalize' }}>{t('listing_detail.book_txt2')}</Button>
          {t('listing_detail.book_txt3')}
        </Typography>
      </Stack>
    </>
  );
}

export default Summary;
