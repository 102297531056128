import * as React from 'react';

import { ExpandLessRounded } from '@mui/icons-material';
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Divider,
  List,
  Paper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LmiTheme from '../../../../../themes/lmi-mui-theme/lmi-theme';
import { DeliveryOptionsModels } from './DeliveryOptions.models';
import Delivery from './Delivery';
import {
  NONE, PICKUPBYLENDER, ROADIE,
} from '../../../../../types/index';

function DeliveryOptions(props: DeliveryOptionsModels) {
  const {
    roadie,
    lenderDelivery,
    changeDeliveryType,
    deliveryType,
    visiblePhoneCode,
  } = props;

  const [expanded, setExpanded] = React.useState<string | false>('expanded');
  const handleChangeExpanded = (panel: string) => (_event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  // const [selectedValue, setSelectedValue] = useState('2')
  const { t } = useTranslation('global');

  const deliveryList = [
    {
      kId: PICKUPBYLENDER,
      kTitle: `${t('delivery.options.pickup_lender')}`,
      kDescription: `${t('delivery.options.pickup_lender_description')}`,
      kShow: lenderDelivery,
    },
    {
      kId: ROADIE,
      kTitle: `${t('delivery.options.roadie')}`,
      kDescription: `${t('delivery.options.roadie_description')}`,
      kShow: roadie,
    },
    {
      kId: NONE,
      kTitle: `${t('delivery.options.none')}`,
      kDescription: `${t('delivery.options.none_description')}`,
      kShow: true,
    },
  ];

  return (
    <>
      <Paper variant="outlined">
        <Accordion
          expanded={expanded === 'expanded'}
          onChange={handleChangeExpanded('expanded')}
          elevation={0}
        >
          <AccordionSummary
            expandIcon={<ExpandLessRounded sx={{ fontSize: '2em', color: LmiTheme.light.accent }} />}
          >
            <Typography sx={{ fontSize: 'h6.fontSize', fontWeight: 'bold' }}>
              {props.kTitle}
            </Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails sx={{ padding: '0' }}>
            <List sx={{ p: 0 }}>
              {
                /* eslint-disable react/jsx-props-no-spreading */
                deliveryList.map((d, i) => {
                  if (d.kId == ROADIE && !visiblePhoneCode) {
                    return null;
                  }
                  if (d.kId == ROADIE) {
                    return null;
                  }

                  return (
                    <Delivery
                      key={d.kId}
                      kValue={i}
                      kDivider={i < deliveryList.length - 1 ?? false}
                      kChecked={d.kId == deliveryType}
                      kOnChange={() => {
                        localStorage.setItem('storageDeliveryType', d.kId);
                        changeDeliveryType(d.kId);
                      }}
                      kSelectedValue={d.kId}
                      kShowContent={d.kShow}
                      kDeliveryType={deliveryType}
                      {...d}
                    />
                  );

                  // )
                })
              }
            </List>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </>
  );
}

DeliveryOptions.defaultProps = {
  kTitle: 'Delivery',
  kContent: 'false',
};

export default DeliveryOptions;
